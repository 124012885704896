import { IMAGE_CATEGORIES } from "../../common/constants/assetCategoriesWithImages";
import { groupArrayElements } from "../utils";
import { useState } from "react";
import PrimaryButton from "../../common/PrimaryButton";
import styled from "styled-components";
import { Header10, Header12, Subheader18 } from "../../common/Typography";
import HotswappsNearYou from '../../../assets/categories/personalized/HotSwappsNearYou.png';
import { hasCommonElement } from "../../common/utils/hasCommonElement";
import { Tooltip } from "@mui/material";
import _ from 'lodash';

const imageCategories = [...IMAGE_CATEGORIES];

const CategoryImage = styled.div`
  margin-bottom: 1rem;
  max-width: ${({ isMobile }) => isMobile ? 120: 190}px;

  @media screen and (min-width: 375px) {
    max-width: 148px;
  }

  @media screen and (min-width: 425px) {
    max-width: 172px;
  }
  
  max-height: 150px;
  border-radius: 14px;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    flex-shrink: 0;
    width: 150%;
    min-height: 100%;
  }
`;

const ThumbnailImage = styled.div`
  margin-bottom: 0.6rem;
  max-width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 1px solid gray;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    flex-shrink: 0;
    min-width: 150%;
    height: 100%;
  }
`;

const CategoryCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ThumbnailCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 80px;
  cursor: pointer;
  
  p {
    height: 20px;
    text-align: center;
  }
`;

const CategoryRow = styled.div`
  display: flex;
  justify-content: ${({ isLast }) => isLast ? 'flex-start' : 'space-between'};
  margin-bottom: 5rem;
  ${({ isMobile }) => isMobile && `
    flex-wrap: wrap;
    margin-bottom: 2rem;
    row-gap: 2rem;
  `}
  column-gap: 2rem;
`;

const ThumbnailRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ isMobile }) => isMobile ? 1.5 : 3}rem;
  column-gap: ${({ isMobile }) => isMobile ? 1.5 : 2.5}rem;
`;

const ActionButton = styled.div`
  min-width: ${({ isMobile }) => isMobile ? '100%' : '450px'};
  margin: 0 auto;
  text-align: center;
`;

const CategoryThumbnailListContainer = styled.div`
  max-width: 100%;
  overflow-x: auto;
  ${({ isMobile }) => isMobile && 'margin-bottom: 1.5rem;'}
`;

const CategoryImageListContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Header = styled(Subheader18)`
  margin-bottom: 2rem !important;
  text-align: ${({ isMobile }) => isMobile ? 'center' : 'auto'};
`;

function getImageCategoriesWithAssets(categories) {
    const categoryValues = categories.map(({ value }) => value);

    if (categoryValues.length > 0) {
        return imageCategories.filter(({ value }) => _.intersection(value, categoryValues).length > 0)
    }

    return imageCategories;
}

export function CategoryImageList({ isMobile, user, onFilterChange, setHideAdvertisement, categories }) {
    const [showLastRow, setShowLastRow] = useState(false);
    const filteredImageCategories = getImageCategoriesWithAssets(categories ?? []);

    let categoryRows = groupArrayElements(filteredImageCategories, 4);
    let lastRow = [];
    if (categoryRows.length > 4) {
        lastRow = categoryRows.pop();
    }

    const ColumnHeaderComponent = isMobile ? Header10 : Header12;
    const personalizedCategories = filteredImageCategories
        .filter((category) => hasCommonElement(user.topcategories || [], category.value))
        .slice(0, 7);

    const showMore = () => {
        setShowLastRow(true);
        setHideAdvertisement(true);
    }

    const showLess = () => {
        setShowLastRow(false);
        setHideAdvertisement(false);
    }

    const getAssetsByCategory = (values) => {
        onFilterChange({ category: values });
        setHideAdvertisement(false);
    }

    return (
        <CategoryImageListContainer>
            <Header isMobile={isMobile}>Check out these finds, tailored just for you!</Header>
            <CategoryThumbnailListContainer isMobile={isMobile}>
                <ThumbnailRow isMobile={isMobile}>
                    {
                        personalizedCategories.map((category) => {
                            return (
                                <ThumbnailCell
                                    key={`category-search-${category.label}`}
                                    onClick={() => getAssetsByCategory(category.value)}
                                >
                                    <ThumbnailImage>
                                        <img src={category.thumbnail} alt={category.label} />
                                    </ThumbnailImage>
                                    <Header10>{category.label}</Header10>
                                </ThumbnailCell>
                            );
                        })
                    }
                    <Tooltip title="Feature coming soon!" arrow placement="right">
                        <ThumbnailCell
                            key="category-search-hot-swaps-near-you"
                        >
                            <ThumbnailImage>
                                <img src={HotswappsNearYou} alt="Hot swaps near you" />
                            </ThumbnailImage>
                            <Header10>Hot swaps near you</Header10>
                        </ThumbnailCell>
                    </Tooltip>
                </ThumbnailRow>
            </CategoryThumbnailListContainer>
            <Header>Swap by Category</Header>
            <div>
                {
                    categoryRows.map((row, i) => {
                        return (
                            <CategoryRow key={`category-row-${i}`} isMobile={isMobile}>
                                {
                                    row.map((category) => {
                                        return (
                                            <CategoryCell
                                                key={`category-search-${category.label}`}
                                                onClick={() => getAssetsByCategory(category.value)}
                                            >
                                                <CategoryImage isMobile={isMobile}>
                                                    <img src={category.image} alt={category.label} />
                                                </CategoryImage>
                                                <ColumnHeaderComponent>{category.label}</ColumnHeaderComponent>
                                            </CategoryCell>
                                        );
                                    })
                                }
                            </CategoryRow>
                        )
                    })
                }
                {
                    showLastRow &&
                    <CategoryRow isMobile={isMobile} isLast={true}>
                        {
                            lastRow.map((category) => {
                                return (
                                    <CategoryCell
                                        key={`category-search-${category.label}`}
                                        onClick={() => getAssetsByCategory(category.value)}
                                    >
                                        <CategoryImage isMobile={isMobile}>
                                            <img src={category.image} alt={category.label} />
                                        </CategoryImage>
                                        <ColumnHeaderComponent>{category.label}</ColumnHeaderComponent>
                                    </CategoryCell>
                                );
                            })
                        }
                    </CategoryRow>
                }
            </div>
            {
                lastRow.length > 0 &&
                <ActionButton isMobile={isMobile}>
                    <PrimaryButton onClick={showLastRow ? showLess : showMore}>
                        {showLastRow ? 'Show less' : `Show more (${lastRow.length})`}
                    </PrimaryButton>
                </ActionButton>
            }
        </CategoryImageListContainer>
    );
}