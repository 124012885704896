import ArtsCollections from '../../../assets/categories/ArtsCollectibles.png';
import ArtsCollectionsThumb from '../../../assets/categories/personalized/ArtsCollectibles.png';
import DIY from '../../../assets/categories/DIY.png';
import DIYThumb from '../../../assets/categories/personalized/DIY.png';
import Education from '../../../assets/categories/Education.png';
import EducationThumb from '../../../assets/categories/personalized/Education.png';
import Electronics from '../../../assets/categories/Electronics.png';
import ElectronicsThumb from '../../../assets/categories/personalized/Electronics.png';
import Entertainment from '../../../assets/categories/Entertainment.png';
import EntertainmentThumb from '../../../assets/categories/personalized/Entertainment.png';
import Fashion from '../../../assets/categories/Fashion.png';
import FashionThumb from '../../../assets/categories/personalized/Fashion.png';
import FitnessWellness from '../../../assets/categories/FitnessWellness.png';
import FitnessWellnessThumb from '../../../assets/categories/personalized/FitnessWellness.png';
import FoodBeverages from '../../../assets/categories/FoodBeverages.png';
import FoodBeveragesThumb from '../../../assets/categories/personalized/FoodBeverages.png';
import GamesToys from '../../../assets/categories/GamesToys.png';
import GamesToysThumb from '../../../assets/categories/personalized/GamesToys.png';
import HairBeauty from '../../../assets/categories/HairBeauty.png';
import HairBeautyThumb from '../../../assets/categories/personalized/HairBeauty.png';
import HomeLiving from '../../../assets/categories/HomeLiving.png';
import HomeLivingThumb from '../../../assets/categories/personalized/HomeLiving.png';
import Pets from '../../../assets/categories/Pets.png';
import PetsThumb from '../../../assets/categories/personalized/Pets.png';
import ProfessionalServices from '../../../assets/categories/ProfessionalServices.png';
import ProfessionalServicesThumb from '../../../assets/categories/personalized/ProfessionalServices.png';
import SocialConnections from '../../../assets/categories/SocialConnections.png';
import SocialConnectionsThumb from '../../../assets/categories/personalized/SocialConnections.png';
import Travel from '../../../assets/categories/Travel.png';
import TravelThumb from '../../../assets/categories/personalized/Travel.png';
import Transportation from '../../../assets/categories/Transport.png';
import TransportationThumb from '../../../assets/categories/personalized/Transportation.png';

export const IMAGE_CATEGORIES = [
    {
        label: 'Art & Design',
        value: ['ART_COLLECTIBLES', 'ART', 'BOOKS', 'PAINTING', 'PHOTOGRAPHY'],
        image: ArtsCollections,
        thumbnail: ArtsCollectionsThumb,
    },
    {
        label: 'DIY',
        value: ['DIY'],
        image: DIY,
        thumbnail: DIYThumb,
    },

    {
        label: 'Education',
        value: ['EDUCATION', 'BOOKS'],
        image: Education,
        thumbnail: EducationThumb,
    },
    {
        label: 'Electronics',
        value: ['ELECTRONICS'],
        image: Electronics,
        thumbnail: ElectronicsThumb,
    },
    {
        label: 'Entertainment',
        value: ['ENTERTAINMENT', 'CONCERT'],
        image: Entertainment,
        thumbnail: EntertainmentThumb,
    },
    {
        label: 'Fashion',
        value: ['FASHION', 'CLOTHING'],
        image: Fashion,
        thumbnail: FashionThumb,
    },
    {
        label: 'Fitness & Wellness',
        value: ['FITNESS_WELLNESS', 'WELLNESS', 'FITNESS', 'BIKES', 'SPORTS'],
        image: FitnessWellness,
        thumbnail: FitnessWellnessThumb,
    },
    {
        label: 'Food and beverages',
        value: ['FOOD_BEVERAGES', 'FOOD_SERVICE', 'GROCERIES', 'GROCERY_SERVICE'],
        image: FoodBeverages,
        thumbnail: FoodBeveragesThumb,
    },
    {
        label: 'Games & Toys',
        value: ['GAME_TOYS', 'TOYS'],
        image: GamesToys,
        thumbnail: GamesToysThumb,
    },

    {
        label: 'Hair & Beauty',
        value: ['HAIR_BEAUTY', 'BEAUTY', 'EYELASHES', 'HAIR', 'NAILS', 'HAIRCUTS', 'TATTOOS'],
        image: HairBeauty,
        thumbnail: HairBeautyThumb,
    },
    {
        label: 'Home & Living',
        value: ['HOME_LIVING', 'HOME', 'FURNITURE', 'CLEANING', 'ASSEMBLY', 'DELIVERY', 'HANDYMAN_SERVICES', 'HOME', 'LAUNDRY', 'MOVING_SERVICE'],
        image: HomeLiving,
        thumbnail: HomeLivingThumb,
    },
    {
        label: 'Professional Services',
        value: ['PROFESSIONAL_SERVICES', 'DATA_ENTRY', 'EVENT_PLANNING', 'WEB_DESIGN', 'PROFESSIONAL_DEVELOPMENT', 'MENTORSHIP'],
        image: ProfessionalServices,
        thumbnail: ProfessionalServicesThumb,
    },
    {
        label: 'Pets',
        value: ['PETS', 'PET_SERVICE'],
        image: Pets,
        thumbnail: PetsThumb,
    },
    {
        label: 'Social Connections',
        value: ['SOCIAL', 'EVENT_PLANNING'],
        image: SocialConnections,
        thumbnail: SocialConnectionsThumb,
    },
    {
        label: 'Transportation',
        value: ['TRANSPORTATION', 'AUTOMOBILE', 'BIKES', 'RIDES'],
        image: Transportation,
        thumbnail: TransportationThumb,
    },
    {
        label: 'Travel',
        value: ['TRAVEL', 'RIDES'],
        image: Travel,
        thumbnail: TravelThumb,
    },
];