import React, { useState } from "react";
import { ContentSection } from "../../../common/Mobile/ContentSection";
import FormControl from "@mui/material/FormControl";
import { Tag } from "../styles";
import {Avatar, Grid, InputAdornment, Tooltip, Typography} from "@mui/material";
import {EditButton} from "../EditButton";
import LocationOffRoundedIcon from "@mui/icons-material/LocationOffRounded";
import { ErrorFieldMessage } from "../../../common/ErrorFieldMessage";
import { DEFAULT_MAX_CATEGORIES_DISPLAY, EthnicityOptions } from "../../constants";
import { TextFormField } from "../../../common/Form/TextFormField";
import useEnableLocation from "../../hooks/useEnableLocation";
import styled from "styled-components";
import {THEME_COLORS} from "../../../common/colors";
import WestRoundedIcon from "@mui/icons-material/WestRounded";
import { Body8, Header12 } from "../../../common/Typography";
import { AssetList, Thumbnail, TruncateButton, UserDisplayName } from "../../styledComponents";
import { Add as AddIcon, Remove as RemoveIcon } from "@material-ui/icons";
import SwitchToggle from "../../../common/Form/SwitchToggle";
import { AddNewAsset } from "../../../assets/components/AddNewAsset";
import Placeholder from "../../../../assets/images/placeholderImage.png";
import { EditImageButton } from "../../components/EditImageButton";
import PrimaryButton from "../../../common/PrimaryButton";
import { FixBannerPageLayout } from "../../../common/Mobile/FixBannerPageLayout";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import OneSignal from "react-onesignal";
import { SaveDraftStatus } from "../SaveDraftStatus";
import { PhoneNumberInput } from "../../components/PhoneNumberInput";

const Header = styled.div`
  h1 {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }
`;

const EditableProfile = styled.div`
  position: relative;
  width: 50%;
  margin: 0 auto;
`;

const TagContainer = styled.div`
  background-color: #E8E8E8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 12px;
  margin-top: 8px;
  margin-bottom: 1.6rem;
  position: relative;
  ${({ isMobile }) => isMobile && 'border-radius: 4px;' }
`;

const LocationContainer = styled.div`
  background-color: #E8E8E8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 12px;
  margin-top: 8px;
  position: relative;
  min-height: ${({ isMobile }) => isMobile ? 32 : 45}px;
  margin-bottom: ${({ isMobile }) => isMobile ? 1 : 1.6}rem;
  ${({ isMobile }) => isMobile && 'border-radius: 4px;' }
`;

const ProfilePicture = styled(Avatar)`
  margin: -52px auto 0 auto !important;
  margin-bottom: 14px !important;
  width: 100% !important;
  height: auto !important;
  border: 4px solid #fff !important;
`;

const TextAreaLimit = styled.div`
  color: ${ THEME_COLORS.primary };
  text-align: right;
  font-size: 0.8rem;
  margin-bottom: 1.4rem;
`;

const SubmitButton = styled(PrimaryButton)`
  display: flex;
  margin: 12px auto !important;
  margin-bottom: 50px !important;
`;

const Subheader = styled.div`
  h3 {
    color: ${ THEME_COLORS.primary };
  }
  margin-bottom: 1.4rem;
  display: flex;
  align-items: center;
  @-webkit-keyframes backandforth {
    0% {
      left:0;
    }
    50% {
      left:58%;
    }
    100%{
      left:0;
    }
  }

  @keyframes backandforth {
    0% {
      left:0;
    }
    50% {
      left:50%;
    }
    100%{
      left:0;
    }
  }
`;

const SubmitButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const TruncateContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const NotificationSection = styled.div`
    display: flex;
    margin: 1rem 0;
    align-items: center;
    width: 100px;
    justify-content: space-between;
`;

const Arrow = styled(WestRoundedIcon)`
  font-size: 30px !important;
  fill: ${ THEME_COLORS.primary } !important;
  position: relative;
  -webkit-animation: backandforth 2s ease 0s;
  -webkit-animation-iteration-count:infinite;
  animation: backandforth 2s ease 0s;
  animation-iteration-count: infinite;
`;

const DraftContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 1rem 0;
`;

export function EditProfileMobile({
  type,
  errors,
  handleFieldChange,
  handleFieldsChange,
  userLookingForList = [],
  topMyAssetCategoriesList = [],
  handleSubmit,
  setAssetId,
  user,
  userAssets,
  setOpenModal,
  onUserFieldChange,
  handleFileUpload,
  fileInputRef,
  saving,
  setNewTags,
  loadingImage,
  draftTimestamp,
  setDraftTimestamp,
}) {
    const [showAllLookingForCategories, setShowAllLookingForCategories] = useState(false);
    const [showAllTopMyAssetCategoriesCategories, setShowAllTopMyAssetCategoriesCategories] = useState(false);
    const userLookingForListDisplay = showAllLookingForCategories ? userLookingForList : userLookingForList.slice(0, DEFAULT_MAX_CATEGORIES_DISPLAY);
    const topMyAssetCategoriesListDisplay = showAllTopMyAssetCategoriesCategories ? topMyAssetCategoriesList : topMyAssetCategoriesList.slice(0, DEFAULT_MAX_CATEGORIES_DISPLAY);

    const { loading: locationLoading, handleEnableLocation, locationDisabled } = useEnableLocation(handleFieldsChange);

    const openAssetForm = (id) => {
        setAssetId(id);
        setOpenModal('ASSET_FORM');
    }

    return (
        <FixBannerPageLayout color="gray" expanded="true">
            <EditableProfile>
                <ProfilePicture
                    variant="round"
                    src={user.profilepicture || Placeholder}
                />
                <EditImageButton onClick={handleFileUpload} fileInputRef={fileInputRef} />
                <Backdrop
                    sx={{ position: 'absolute', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loadingImage}
                >
                    <CircularProgress color="secondary" />
                </Backdrop>
            </EditableProfile>
            {
                type === 'newAccount' &&
                <Header>
                    <UserDisplayName component="h1">
                        {user.firstname} {user.surname}
                    </UserDisplayName>
                        <Subheader>
                            <Typography component="h3">
                                Hey, there! Let’s start by completing your profile
                            </Typography>
                            <div style={{position: 'relative'}}>
                                <Arrow/>
                            </div>
                        </Subheader>
                </Header>
            }
            <DraftContainer>
                <SaveDraftStatus
                    user={user}
                    draftTimestamp={draftTimestamp}
                    setDraftTimestamp={setDraftTimestamp}
                    isMobile={true}
                />
            </DraftContainer>
            <ContentSection>
                <Grid container justifyContent="space-between">
                    <Grid item xs={12} sm={12} lg={5.45}>
                        <Header12 variant="body1" gutterBottom>
                            First name*
                        </Header12>
                        <TextFormField
                            variant="filled"
                            color="warning"
                            isMobile
                            error={!!errors.firstname}
                            helperText={errors.firstname}
                            onChange={handleFieldChange('firstname')}
                            value={user.firstname}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={5.45}>
                        <Header12 gutterBottom>
                            Last name*
                        </Header12>
                        <TextFormField
                            color="warning"
                            isMobile
                            id="form-surname"
                            error={!!errors.surname}
                            helperText={errors.surname}
                            variant="filled"
                            onChange={handleFieldChange('surname')}
                            value={user.surname}
                        />
                    </Grid>
                </Grid>
                <FormControl sx={{ m: 1, width: '100%', margin: '8px 0' }}>
                    <Header12 variant="body1" gutterBottom>
                        Location*
                    </Header12>
                    <Grid container>
                        <Grid item xs={12} sm={12} lg={12}>
                            {
                                locationDisabled &&
                                <TextFormField
                                    variant="filled"
                                    color="warning"
                                    isMobile
                                    error={!!errors.displaylocation}
                                    helperText={errors.displaylocation}
                                    placeholder="Brooklyn, NY, United States"
                                    onChange={handleFieldChange('displaylocation')}
                                    value={user.displaylocation}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <LocationOffRoundedIcon />
                                            </InputAdornment>,
                                    }}
                                />
                            }
                            {
                                !locationDisabled &&
                                <LocationContainer isMobile>
                                    {user.displaylocation || 'No location'}
                                    <EditButton
                                        onClick={handleEnableLocation}
                                        loading={locationLoading}
                                        disabled={locationDisabled}
                                        isMobile
                                    />
                                </LocationContainer>
                            }

                        </Grid>
                    </Grid>
                </FormControl>
            </ContentSection>
            <ContentSection>
                <Header12>My pronouns</Header12>
                <TagContainer isMobile>
                    <div>
                        {
                            user.pronouns.map((pronoun) => (
                                <Tag key={`${pronoun}-tag`} isMobile>
                                    {pronoun}
                                </Tag>
                            ))
                        }
                    </div>
                    <EditButton onClick={() => setOpenModal('PRONOUNS')} isMobile />
                </TagContainer>
                <ErrorFieldMessage
                    message={errors.pronouns}
                    isVisible={errors.pronouns}
                />
                <Header12>My ethnicity</Header12>
                <TagContainer isMobile>
                    <div>
                        {
                            EthnicityOptions.options
                                .filter(({ value }) => user.ethnicity.includes(value))
                                .map(({ tag }) => (
                                    <Tag key={`${tag}-ethnicity-tag`} isMobile>
                                        {tag}
                                    </Tag>
                                ))
                        }
                    </div>
                    <EditButton
                        isMobile
                        onClick={() => {
                            setNewTags(user.ethnicity);
                            setOpenModal('ETHNICITIES');
                        }}
                    />
                </TagContainer>
                <ErrorFieldMessage
                    message={errors.ethnicity}
                    isVisible={errors.ethnicity}
                />
            </ContentSection>
            <ContentSection>
                <Header12 variant="body1" gutterBottom>
                    About me*
                </Header12>
                <TextFormField
                    id="outlined-multiline-flexible"
                    multiline
                    isMobile
                    color="warning"
                    error={!!errors.description}
                    helperText={errors.description}
                    placeholder="Here is some inspo for you: Hey! Looking to swap for concert or festival tickets (anybody said Beyonce?!), holistic wellness practices, vegan meals & more. DM with your suggestions. Been doing lashes and nails for over 10 years now & I love it!. Also down to sit your doggy or bake you cake!"
                    rows={5}
                    onChange={handleFieldChange('description')}
                    value={user.description}
                    variant="filled"
                />
                <TextAreaLimit>
                    500 characters or less
                </TextAreaLimit>
            </ContentSection>
            {
                userAssets.length > 0 &&
                <ContentSection>
                    <Header12>My wallet*</Header12>
                    <AssetList>
                        {
                            userAssets.map((asset) => {
                                return (
                                    <div key={`asset-list-${asset.category}`} style={{margin: 4, maxWidth: 80}}>
                                        <Thumbnail onClick={ () => openAssetForm(asset.id)}>
                                            <img alt="placeholder" src={asset.mainImage} />
                                        </Thumbnail>
                                        <Typography variant="body2">{asset.title}</Typography>
                                    </div>
                                )
                            })
                        }
                        {
                            userAssets.length < 6 &&
                            <div key="new-asset-create-card" style={{ margin: 4, maxWidth: 80}}>
                                <AddNewAsset
                                    size="thumbnail"
                                    header={<Typography sx={{ marginTop: '5px !important' }} variant="body2">Add new</Typography>}
                                    openAssetForm={() => openAssetForm(null)} />
                            </div>
                        }
                    </AssetList>
                </ContentSection>
            }
            <ContentSection>
                <Header12>Looking for*</Header12>
                {
                    type === 'newAccount' &&
                    <Body8>Products, services or skills I want to get</Body8>
                }
                <TagContainer isMobile>
                    <div>
                        {
                            userLookingForListDisplay
                                .map(({ tag }) => (
                                    <Tag key={`${tag}-top-tag`} isMobile>
                                        {tag}
                                    </Tag>
                                ))
                        }
                        <TruncateContainer>
                            {
                                userLookingForList.length > DEFAULT_MAX_CATEGORIES_DISPLAY &&
                                <TruncateButton
                                    size="small"
                                    onClick={() => setShowAllLookingForCategories(!showAllLookingForCategories)}
                                    startIcon={showAllLookingForCategories ? <RemoveIcon /> : <AddIcon />}
                                >
                                    {showAllLookingForCategories ? 'Less': 'More'}
                                </TruncateButton>
                            }
                        </TruncateContainer>
                    </div>
                    <EditButton onClick={() => setOpenModal('LOOKING_FOR_CATEGORIES')}  isMobile />
                </TagContainer>
                <ErrorFieldMessage
                    offsetMargin={true}
                    message={errors.topcategories}
                    isVisible={errors.topcategories}
                />
                <Header12>What can you provide in return?*</Header12>
                {
                    type === 'newAccount' &&
                    <Body8>Skills, products or services I can provide</Body8>
                }
                <TagContainer isMobile>
                    <div>
                        {
                            topMyAssetCategoriesListDisplay
                                .map(({ tag }) => (
                                    <Tag key={`${tag}-top-tag`} isMobile>
                                        {tag}
                                    </Tag>
                                ))
                        }
                        <TruncateContainer>
                            {
                                userLookingForList.length > DEFAULT_MAX_CATEGORIES_DISPLAY &&
                                <TruncateButton
                                    size="small"
                                    onClick={() => setShowAllTopMyAssetCategoriesCategories(!showAllTopMyAssetCategoriesCategories)}
                                    startIcon={showAllTopMyAssetCategoriesCategories ? <RemoveIcon /> : <AddIcon />}
                                >
                                    {showAllTopMyAssetCategoriesCategories ? 'Less': 'More'}
                                </TruncateButton>
                            }
                        </TruncateContainer>
                    </div>
                    <EditButton onClick={() => setOpenModal('IN_RETURN_CATEGORIES')}  isMobile />
                </TagContainer>
                <ErrorFieldMessage
                    offsetMargin={true}
                    message={errors.top_my_asset_categories}
                    isVisible={errors.top_my_asset_categories}
                />
                <Header12>When I swap, I value*</Header12>
                <TagContainer isMobile>
                    <div>
                        {
                            user.profilevalues.map((value) => {
                                return (
                                    <Tag key={`${value}-tag`} isMobile>
                                        {value}
                                    </Tag>
                                )
                            })
                        }
                    </div>
                    <EditButton onClick={() => setOpenModal('VALUES')} isMobile />
                </TagContainer>
            </ContentSection>
            <ContentSection>
                <Grid container justifyContent="space-between">
                    <Grid item xs={12} sm={12} lg={5.45}>
                        <Header12 variant="body1" gutterBottom>
                            Email
                        </Header12>
                        <TextFormField
                            variant="filled"
                            color="warning"
                            isMobile
                            error={!!errors.username}
                            disabled
                            helperText={errors.username}
                            onChange={(e) => {
                                handleFieldsChange({
                                    email: e.target.value,
                                    username: e.target.value,
                                })
                            }}
                            value={user.username}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={5.45}>
                        <Header12 gutterBottom>
                            Phone number*
                        </Header12>
                        <PhoneNumberInput
                            color="warning"
                            isMobile
                            id="form-phone-number"
                            error={!!errors.phone}
                            helperText={errors.phone}
                            variant="filled"
                            onChange={(phone) => {
                                handleFieldsChange({
                                    phone,
                                })
                            }}
                            value={user.phone}
                        />
                    </Grid>
                </Grid>
                <Header12 variant="body1" gutterBottom>
                    Notifications
                </Header12>
                <NotificationSection>
                    <Header12>
                        Email:
                    </Header12>
                    <SwitchToggle defaultChecked={user.notificationmail}
                                  onChange={onUserFieldChange('notificationmail')}/>
                </NotificationSection>
                <NotificationSection>
                    <Header12>
                        Web:
                    </Header12>
                    <SwitchToggle defaultChecked={user.notificationpush} onChange={(e) => {
                        if (e.target.checked) {
                            window.localStorage.removeItem('onesignal-notification-prompt');
                            OneSignal.Slidedown.promptPush();
                        }

                        onUserFieldChange('notificationpush')(e);
                    }}/>
                </NotificationSection>
                <Header12 variant="body1" gutterBottom>
                    Display recent Swaps
                </Header12>
                <SwitchToggle defaultChecked={user.show_savings} onChange={onUserFieldChange('show_savings')} />
            </ContentSection>
            <SubmitButtonContainer>
                <SubmitButton
                    onClick={handleSubmit}
                    disabled={saving}
                >
                    Done
                </SubmitButton>
            </SubmitButtonContainer>
        </FixBannerPageLayout>
    );
}