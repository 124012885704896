import React, {useEffect, useState} from "react";
import moment from "moment/moment";
import Chip from "@mui/material/Chip";

function saveProfileDraft(user) {
    localStorage.setItem(`draft-profile-${user.uuid}`, JSON.stringify(user));
}

export function SaveDraftStatus({ user, draftTimestamp, setDraftTimestamp, isMobile }) {
    const userData = localStorage.getItem(`draft-profile-${user.uuid}`);

    useEffect(() => {
        saveProfileDraft(user);
        setDraftTimestamp(moment().format('MMM DD hh:mma'))
    }, [user]);

    const handleDelete = () => {
        localStorage.removeItem(`draft-profile-${user.uuid}`);
        window.location.reload();
    };

    useEffect(() => {
        if(!userData) {
            setDraftTimestamp(null);
        }
    }, [userData]);



    if (userData && draftTimestamp) {
        return (
            <Chip
                label="Autosaved as draft"
                onDelete={handleDelete}
            />
        )
    }

    return null;
}