import React, { useState } from "react";
import useLoginForm from "./useLoginForm";
import { Header12, Header22, Subheader16 } from "../../common/Typography";
import { FormInput } from "../../common/Form/FormInput";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@material-ui/core/Typography";
import { SecondaryButton } from "../SecondaryButton";
import styled from "styled-components";
import { styled as muiStyled } from "@mui/material/styles";
import FormCheckbox from "../../common/Form/FormCheckbox";
import IconButton from "@material-ui/core/IconButton";
import Link from "@mui/material/Link";
import { AuthCredentialButtons } from "./AuthCredentialButtons";
import {THEME_COLORS} from "../../common/colors";

const Subheader = styled(Subheader16)`
  margin-bottom: 36px !important;
`;

const CustomCheckbox = muiStyled(FormCheckbox)(() => ({
    '&': {
        padding: 0,
        paddingRight: '4px !important',
    },
}));

const PasswordIcon = styled(IconButton)`
  position: absolute !important;
  right: 14px !important;
  bottom: 18px !important;
`

const SignupSuccess = styled(Header12)`
    color: ${({ type }) => type === 'success' ? 'mediumseagreen' : 'indianred' } !important;
    margin-bottom: 12px !important;
    ${({ isMobile }) => isMobile && 'text-align: center !important;'}
`;

const MinimalLink = styled(Link)`
  color: #4e5152 !important;
  text-decoration: unset !important;
  align-self: center;
`;

export function LoginForm({ queryDefaultParams, isMobile }) {
    const login = (data) => {
        if (data.success) {
            window.location = '/search?message=login_success';
        }
    };

    const {
        displayValidationMessage,
        inputs,
        handleInputChange,
        handleLoginSubmit,
        handleAuthSubmit,
        loading
    } = useLoginForm(login, queryDefaultParams);

    const [showPassword, setShowPassword] = useState(false);
    return (
        <div>
            <Header22 component="h1">
                Hello!
            </Header22>
            {
                displayValidationMessage.type &&
                <SignupSuccess
                    isMobile={isMobile}
                    type={displayValidationMessage.type}
                >
                    {displayValidationMessage.message}
                </SignupSuccess>
            }
            <Subheader component="h2">
                Please fill your credentials below
            </Subheader>
            <form onSubmit={handleLoginSubmit}>
                <FormInput
                    htmlFor="username"
                    type="email"
                    name="username"
                    onChange={handleInputChange}
                    value={inputs.username}
                    id="username"
                    required
                    label="Email"
                />
                <div style={{ position: 'relative', marginTop: '12px' }}>
                    <FormInput
                        htmlFor="password"
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        onChange={handleInputChange}
                        value={inputs.password}
                        id="password"
                        required
                        label="Password"
                    />
                    <PasswordIcon
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={() => setShowPassword(!showPassword)}
                        edge="end"
                    >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </PasswordIcon>
                </div>
                <Grid container>
                    <Grid item xs={6} md={6} xl={6}>
                        <CustomCheckbox
                            id="rememberMe"
                            name="rememberMe"
                            value={inputs.rememberMe}
                            size="small"
                            onChange={handleInputChange}
                        />
                        <Typography component="span" variant="body2">
                            Remember me
                        </Typography>
                    </Grid>
                    <Grid
                        xs={6}
                        md={6}
                        xl={6}
                        display="flex"
                        sx={{ justifyContent: 'flex-end' }}
                    >
                        <MinimalLink href="/forgotpassword" variant="body2">
                            Forgot password
                        </MinimalLink>
                    </Grid>
                </Grid>
                <SecondaryButton
                    color={Object.values(inputs).every(v => v) ? THEME_COLORS.primary : undefined}
                    loading={loading}
                >
                    Log in
                </SecondaryButton>
            </form>
            <AuthCredentialButtons onAuthSubmit={handleAuthSubmit} action="Log in" />
            <MinimalLink href="/signup" variant="body1">
                Don't have an account? <b>Sign Up</b>
            </MinimalLink>
        </div>
    );
}