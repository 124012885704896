import React from "react";
import styled from 'styled-components';
import { styled as muiStyled, useTheme } from '@mui/material/styles';

import HomeLogo from "../../common/HomeLogo";
import { NavBar } from "../../common/NavBar";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Drawer from "@mui/material/Drawer";
import AdVertical from "../../../assets/ads/MoreAssetsVertical.png";

const drawerWidth = 276;

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
`;

const Header = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  min-height: 100px;
  grid-template-columns: 1.6fr 0.6fr 1fr 0.6fr 1.2fr;
  gap: 0;
  grid-auto-flow: row;
  grid-template-areas: "Logo Navigation Navigation Navigation RightHeader";
`;

const HeaderContainer = styled.div`
  min-height: 100px;
`;

const Logo = styled.div`
  grid-area: Logo;
  display: flex;
  align-items: center;
  padding-left: 4rem;
  padding-top: 24px;
`;

const Navigation = styled.div`
  grid-area: Navigation;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-left: 5rem;
  padding-right: 4rem;
`;

const RightHeader = styled.div`
  grid-area: RightHeader;
  text-align: center;
  display: flex;
  align-items: flex-end;
  ${({hideRight}) => !hideRight && 'background-color: #F4F5F4;'}
`;

const Section = styled.div`
  min-height: 50px;
  max-width: 400px;
  width: 100%;
  ${({ type }) =>  type === 'view' && `
    margin: 0 auto;
    max-width: 640px;
  `}
`;

const Form = styled.div`
  width: 100%;
  display: grid;
  height: 100%;
  position: relative;
  grid-template-columns: 1.6fr 0.6fr 1fr 0.6fr 1.2fr;
  gap: 0;
  grid-auto-flow: row;
  grid-template-areas: "MiddlePanel MiddlePanel MiddlePanel MiddlePanel ${({hideRight}) => hideRight ? 'MiddlePanel' : 'RightPanel'}";
`;

const RightPanel = styled.div`
  grid-area: RightPanel;
  background-color: #F4F5F4;
  padding: 0 2rem 100px 2rem;
  img {
    width: 100%;
    height: auto;
  }
`;

const MiddlePanel = styled.div`
  grid-area: MiddlePanel;
  padding: 0 4rem;
  padding-bottom: 100px;
`;

const DrawerButton = styled.div`
  position: fixed;
  background-color: #F4F5F4;
  left: 0;
  top: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DrawerCloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.3rem;
`;

const DrawerContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Main = muiStyled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        gridArea: 'MiddlePanel',
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(!open && {
            paddingLeft: '5rem',
        }),
        ...(open && {
            padding: '0 9%',
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const LeftDrawer = muiStyled(Drawer)(
    ({ theme, open }) => ({
        gridArea: 'LeftPanel',
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: '#F4F5F4',
        },
    }),
);

export function PageLayout({ leftPanel, hideRightPanel, children }) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Container>
            <HeaderContainer>
                <Header staticPanel={open}>
                    <Logo>
                        <Section>
                            <HomeLogo position="absolute" />
                        </Section>
                    </Logo>
                    <Navigation>
                     <Section>
                        <NavBar condense={true} />
                     </Section>
                    </Navigation>
                    <RightHeader hideRight={hideRightPanel}>
                        <Section />
                    </RightHeader>
                </Header>
            </HeaderContainer>
            <Form staticPanel={open} hideRight={hideRightPanel}>
                {
                    !!leftPanel &&
                    <LeftDrawer
                        anchor="left"
                        open={open}
                    >
                        <div style={{ display: 'flex', height: 100 }}>
                            <Logo>
                                <Section>
                                    <HomeLogo position="absolute" />
                                </Section>
                            </Logo>
                        </div>
                        <DrawerContainer>
                            {leftPanel}
                            <DrawerCloseButton>
                                <IconButton onClick={handleDrawerClose}>
                                    {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                                </IconButton>
                            </DrawerCloseButton>
                        </DrawerContainer>
                    </LeftDrawer>
                }
                <MiddlePanel>
                    { children }
                </MiddlePanel>
                {
                    !hideRightPanel &&
                    <RightPanel>
                        <img src={AdVertical} alt="Add more assets tip." />
                    </RightPanel>
                }
            </Form>
        {
            !!leftPanel &&
            <DrawerButton onClick={handleDrawerOpen}>
                <ChevronRightIcon/>
            </DrawerButton>
        }
        </Container>
    );
}