import React from 'react';
import LoginBg from '../../../assets/images/login_bg.png';
import { SplitImageLayoutForm } from "../../common/SplitLayoutForm";
import { LoginForm } from "./LoginForm";
import styled from "styled-components";
import { THEME_COLORS } from "../../common/colors";
import { MobileForm } from "../../common/Mobile/MobileForm";
import HomeLogo from "../../common/HomeLogo";
import FORMAT_SIZES from "../../common/constants/FormatSizes";

const MobileBackground = styled.div`
  background-color: ${THEME_COLORS.primary};
  height: 100vh;
`;

const MobileContainer = styled.div`
  h1, h2 {
   text-align: center; 
  }
  
  h1 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
`;

function LoginWeb({ queryDefaultParams }) {
    return (
        <SplitImageLayoutForm
            imagePosition="left"
            $logoPosition="right"
            image={LoginBg}
        >
            <LoginForm queryDefaultParams={queryDefaultParams} />
        </SplitImageLayoutForm>
    );
}

function LoginMobile({ queryDefaultParams }) {
    return (
        <MobileBackground>
            <MobileForm>
                <HomeLogo
                    noLink
                    width={190}
                    alignment="center"
                />
                <MobileContainer>
                    <LoginForm
                        queryDefaultParams={queryDefaultParams}
                        isMobile={true}
                    />
                </MobileContainer>
            </MobileForm>
        </MobileBackground>
    );
}

const Login = ({ format, queryDefaultParams }) => {
  return (
      format === FORMAT_SIZES.MOBILE ?
          <LoginMobile
              queryDefaultParams={queryDefaultParams}
          /> :
          <LoginWeb
              queryDefaultParams={queryDefaultParams}
          />
  );
};

export default Login;
