import React, {useEffect, useState} from "react";
import Divider from '@mui/material/Divider';
import {Avatar, Box, Button, Grid, Typography} from "@mui/material";
import styled from "styled-components";
import PlaceIcon from '@mui/icons-material/LocationOnRounded';
import Placeholder from '../../../assets/images/placeholderImage.png';
import PrimaryButton from "../../common/PrimaryButton";
import SendRounded from "@mui/icons-material/SendRounded";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import GroupedChatList from "./GroupedChatList";
import useChatRoom from "./useChatRoom";
import useSwapContract from "./useSwapContract";
import ContractForm from "../swappContract/ContractForm";
import ContractView from "../swappContract/ContractView";
import ReviewFormModal from "../../review/reviewFormModal";
import useUpdateChatStatus from "./useUpdateChatStatus";
import Link from "@material-ui/core/Link";
import moment from "moment/moment";
import useFetchReviews from "../../review/useFetchReviews";
import {Body8} from "../../common/Typography";
import {styled as styledMUI} from "@mui/material/styles";
import {THEME_COLORS} from "../../common/colors";
import useFetchAssetDetails from "../../assets/hooks/useFetchAssetDetails";

const ChatRoomContainer = styled.div`
  ${({ isMobile }) => !isMobile && 'margin: 0 24px;'}
`;

const UserDetails = styled(Grid)`
  display: flex !important;
  max-height: 75px !important;
  .MuiAvatar-root, img {
    width: ${({ isMobile }) => isMobile ? 50 : 70}px !important;
    height: ${({ isMobile }) => isMobile ? 50 : 70}px !important;
  }
`;

const UserDescription = styled.div`
  margin-left: 12px;
  color: #717273;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
`;

const AssetTitle = styled(Typography)`
  font-size: 0.98rem !important;
  font-weight: 400 !important;
`;

const CurrentUserDetails = styled(Body8)`
  font-weight: 700 !important;
  margin-bottom: 0.2rem !important;
`;

const Location = styled(Typography)`
  font-size: 0.7rem !important;
  margin-bottom: 12px !important;
  display: flex !important;
  align-items: center !important;
`;

const LocationIcon = styled(PlaceIcon)`
  fill: #717273 !important;
  font-size: 1.25rem !important;
  margin-left: -0.3rem !important;
  margin-right: 0.1rem !important;
`;

const ChatroomhHeader = styled(Grid)`
  display: flex !important;
  justify-content: space-between !important;
  margin-bottom: 24px !important;
`;

const ChatActions = styled(Grid)`
  display: flex !important;
  ${({ isMobile }) => !isMobile && `justify-content: flex-end !important;`}
  align-items: center !important;
  gap: 1rem !important;
`;

const SwappButton = styled(PrimaryButton)`
  max-width: 100px !important;
  font-size: 0.75rem !important;
`;

const InputContainer = styled.div`
  border-radius: 24px;
  border: 1px gray solid;
  margin-top: 4px;
  padding: 4px 16px;
`;

const MessageInput = styled(InputBase)`
  font-size: 14px !important;
  width: 85% !important;
`;

const SendButton = styled(IconButton)`
  float: right !important;
  padding: 0 !important;
  right: -8px !important;
  top: 3px !important;
`;

const MessageList = styled(Box)`
  height: calc(100vh - ${({ isMobile }) => isMobile ? 330 : 280}px) !important;
  overflow-y: auto !important;
  ${({ isMobile }) => isMobile && 'margin: 0 -1.5rem;'}
`;

const MinimalLink = styled(Link)`
  color: #4e5152 !important;
  text-decoration: unset !important;
`;

const PublicButton = styledMUI(Button)(({ width }) => ({
    color: '#fff',
    textTransform: 'capitalize',
    fontWeight: '400',
    minWidth: 25,
    backgroundColor: THEME_COLORS.primary,
    borderRadius: 4,
    width: `${width}%`,
    boxShadow: 'unset',
    '&:hover': {
        backgroundColor: THEME_COLORS.primaryHover,
    },
}));


function ChatRoom({
  chatDetails,
  currentUserId,
  onUpdateMessageRead,
  fetchChatDetailsBySerialId,
  isMobile,
  format,
}) {
    const currentUser = chatDetails.owner.id === currentUserId ? chatDetails.owner : chatDetails.requester;
    const otherUser = chatDetails.owner.id === currentUserId ? chatDetails.requester : chatDetails.owner;
    const Button = isMobile ? PublicButton : SwappButton;
    const {
        chatroomInfo,
        sendChatMessage,
        sendMessage,
        handleMessage,
        message } = useChatRoom(chatDetails, currentUser.id, otherUser.id, onUpdateMessageRead, fetchChatDetailsBySerialId);
    const [openContractModal, setOpenContractModal] = useState('');
    const [initContract, setInitContract] = useState({});

    const {
        contractDetails,
        fetchContract,
        updatedContractId,
        loading,
    } = useSwapContract(chatroomInfo.id, chatroomInfo.serialId, chatroomInfo.contractId, chatDetails.owner.id, chatDetails.requester.id, setOpenContractModal, fetchChatDetailsBySerialId);

    const { reviews, fetchReview } = useFetchReviews('contract', contractDetails.id);
    const handleUpdateSwapContract = (id) => {
        fetchContract(id);
        fetchReview(contractDetails.id ?? id);
        fetchChatDetailsBySerialId(chatroomInfo.serialId);
    }

    const contractUser = currentUser.id === (contractDetails.requesterUserContract ?? {}).userId ?
        contractDetails.requesterUserContract : contractDetails.requestedUserContract;

    const otherContractUser = currentUser.id === (contractDetails.requesterUserContract ?? {}).userId ?
        contractDetails.requestedUserContract : contractDetails.requesterUserContract;

    const { updateContractStatus, isLoading: isCompletingSwappLoading } = useUpdateChatStatus(handleUpdateSwapContract, currentUser, setOpenContractModal, fetchChatDetailsBySerialId);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleMessage(event);
            sendChatMessage();
        }
    };

    useEffect(() => {
        if (!loading && !contractUser?.dateOfCompletion) {
            setInitContract({
                chatId: chatDetails.id,
                serialId: chatDetails.serialId,
                requestedUserId: chatDetails.owner.id,
                requesterUserId: chatDetails.requester.id,
                requestedAsset: chatDetails.asset,
            })
            setOpenContractModal('CONTRACT_EDIT');
        }
    }, [loading, contractUser, chatDetails]);

    return (
        <ChatRoomContainer isMobile={isMobile}>
            <ChatroomhHeader container>
                <UserDetails item xs={12} sm={5} lg={5} isMobile={isMobile}>
                    <Avatar alt="user-profile-picture" src={chatDetails.asset.mainImage ?? Placeholder} />
                    <UserDescription>
                        <MinimalLink href={`/assets/${chatDetails.asset.uuid}`}>
                            <AssetTitle variant="h6">{chatDetails.asset.title}</AssetTitle>
                        </MinimalLink>
                        <CurrentUserDetails variant="h6">{chatDetails.owner.firstName} {chatDetails.owner.lastName}</CurrentUserDetails>
                        <Location variant="h4">
                            <LocationIcon fontSize="small"/>
                            {chatDetails.owner.displayLocation || 'No Location'}
                        </Location>
                    </UserDescription>
                </UserDetails>
                    <ChatActions item xs={12} sm={5} lg={5} isMobile={isMobile}>
                        {!chatDetails.contractId &&
                            <Button
                                onClick={() => setOpenContractModal('CONTRACT_EDIT')}
                                disabled={loading}
                                width={100}
                            >
                                Submit an offer
                            </Button>
                        }
                        {chatDetails?.contractId && contractUser?.id && contractUser?.status !== 'APPROVED' &&
                            <Button
                                onClick={() => setOpenContractModal('CONTRACT_EDIT')}
                                width={100}
                            >
                                Edit offer
                            </Button>
                        }
                        {
                            chatDetails?.contractId &&
                            contractUser?.id &&
                            <Button
                                onClick={() => setOpenContractModal('CONTRACT_VIEW')}
                                width={100}
                            >
                                {otherContractUser?.status === 'APPROVED' ? 'View Swap' : 'Ready to Swap'}
                            </Button>
                        }
                        {chatDetails?.contractId &&
                            contractUser?.id &&
                            chatDetails.status !== 'COMPLETED' &&
                            contractUser?.status === 'APPROVED' &&
                            otherContractUser?.status === 'APPROVED' &&
                            <Button
                                sx={{ maxWidth: '140px !important' }}
                                onClick={() => updateContractStatus(chatroomInfo.id, chatroomInfo.serialId, 'COMPLETED')}
                                disabled={isCompletingSwappLoading}
                                width={100}
                            >
                                Complete swap
                            </Button>
                        }
                        {chatDetails?.contractId &&
                            contractUser?.id &&
                            chatDetails.status === 'COMPLETED' &&
                            <Button
                                sx={{ maxWidth: '140px !important' }}
                                onClick={() => setOpenContractModal('REVIEW_FORM')}
                            >
                                {reviews.reviews.length > 0 ? 'Edit Review' : 'Review Swap'}
                            </Button>
                        }
                    </ChatActions>
            </ChatroomhHeader>
            <Divider />
            <MessageList isMobile={isMobile}>
                {
                    Object.entries(chatroomInfo.messages ?? [])
                        .sort(([day], [day2]) => day > day2 ? 1 : -1)
                        .map(([day, messages]) => {
                            const date = moment(messages[0].sentAt).format('dddd MMMM DD, YYYY');
                        return (
                            <GroupedChatList
                                key={`grouped-chat-list-${day}`}
                                date={date}
                                messages={messages}
                                currentUser={currentUser}
                                otherUser={otherUser}
                                isMobile={isMobile}
                            />
                        )
                    })
                }
            </MessageList>
            <InputContainer>
                <MessageInput
                    placeholder="Write Something..."
                    value={message}
                    onChange={handleMessage}
                    onKeyDown={handleKeyPress}
                    disabled={chatDetails.status === 'COMPLETED'}
                    inputProps={{ 'aria-label': 'search my assets' }}
                    size="small"
                />
                <SendButton
                    type="button"
                    aria-label="send message"
                    disabled={chatDetails.status === 'COMPLETED'}
                    onClick={sendChatMessage}
                >
                    <SendRounded fontSize="medium" />
                </SendButton>
            </InputContainer>
                <ContractForm
                    isOpen={openContractModal === 'CONTRACT_EDIT'}
                    contractId={updatedContractId ?? chatDetails.contractId}
                    initContract={initContract}
                    contractUser={contractUser}
                    userData={currentUser}
                    otherUser={otherUser}
                    isMobile={isMobile}
                    asset={chatDetails.asset}
                    otherContractUser={otherContractUser}
                    setOpenContractModal={setOpenContractModal}
                    onUpdateSwapContract={handleUpdateSwapContract}
                    format={format}
                />
            <ContractView
                isOpen={openContractModal === 'CONTRACT_VIEW'}
                contractId={chatDetails.contractId}
                contractUser={contractUser}
                currentUser={currentUser}
                otherUser={otherUser}
                isMobile={isMobile}
                otherContractUser={otherContractUser}
                setOpenContractModal={setOpenContractModal}
                onUpdateSwapContract={handleUpdateSwapContract}
                format={format}
            />
            <ReviewFormModal
                isOpen={openContractModal === 'REVIEW_FORM'}
                contractId={chatDetails.contractId}
                contractUser={contractUser}
                otherUser={otherUser}
                otherContractUser={otherContractUser}
                setOpenReviewModal={setOpenContractModal}
                userId={currentUser.id}
                isMobile={isMobile}
                currentUser={currentUser}
                onReviewSubmission={handleUpdateSwapContract}
                format={format}
            />
        </ChatRoomContainer>
    );
}

export default ChatRoom;