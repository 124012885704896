import axios from 'axios';
import _ from 'lodash';
import { useContext, useState } from 'react';
import { AuthContext } from "../../app/AuthContext";
import { fromApi } from "../models/user";

function useUserInfo(userData) {
    const { authContext } = useContext(AuthContext);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState(fromApi(userData));

  const fetchProfile = () => {
    axios
      .get(`${process.env.REACT_APP_PUBLIC_API_URL}/users/profile`, {
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'x-access-token': authContext.token,
        },
      })
      .then(response => {
          if (response.data.success) {
              setUserInfo(response.data[0]);
          }
            setLoaded(true);
            setLoading(false);
            return response.data;
      })
      .catch(error => {
        if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
          setLoaded(true);
          setLoading(false);
      });
  };

  if (!loading && !loaded && !userInfo.email && !_.isEmpty(userData)) {
      setLoading(true);
      setUserInfo(fromApi(userData));
  }

  return { userInfo, loaded, fetchProfile, setUserInfo };
};

export default useUserInfo;
