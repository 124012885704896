import axios from 'axios';
import { useState, useContext } from 'react';
import _ from 'lodash';
import { AuthContext } from '../app/AuthContext';
import { fromApi } from "../common/models/chatOverview";

const ACTIVE_CHAT_MAX_LIMIT = 7;

const useFetchChats = () => {
  const [loaded, setLoaded] = useState(false);
  const { authContext } = useContext(AuthContext);
  const { token } = authContext;
  const [activeChats, setActiveChats] = useState([]);

  const fetchCurrentUserChats = (search) => {
    axios
      .get(`${process.env.REACT_APP_PUBLIC_API_URL}/chat/previews?${search ? `search=${search}`: `limit=${ACTIVE_CHAT_MAX_LIMIT}`}`, {
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'x-access-token': token,
        },
      })
      .then(response => {
        setActiveChats((response.data ?? []).map(fromApi));
        return response.data;
      })
      .catch(error => {
        if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
      });
  };

  const handleUpdateMessageRead = (serialId, count) => {
    const activeChatIndex = activeChats.findIndex((swap) => swap.serialId === serialId);
    if (activeChatIndex > -1) {
      setActiveChats(activeChats.toSpliced(activeChatIndex, 1, { ...activeChats[activeChatIndex], unreadMessageCount: count ?? 0 }))
    }
  }

  if (_.isEmpty(activeChats) && loaded === false) {
    fetchCurrentUserChats();
    setLoaded(true);
  }
  return { activeChats, handleUpdateMessageRead, fetchCurrentUserChats };
};

export default useFetchChats;
