import React from "react";
import { Grid, InputAdornment, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { EthnicityOptions } from "../constants";
import styled from "styled-components";
import { EditButton } from "./EditButton";
import { SubHeader, Tag } from "./styles";
import { ErrorFieldMessage } from "../../common/ErrorFieldMessage";
import PrimaryButton from "../../common/PrimaryButton";
import { TextFormField as TextField } from "../../common/Form/TextFormField";
import { THEME_COLORS } from "../../common/colors";
import WestRoundedIcon from '@mui/icons-material/WestRounded';
import LocationOffRoundedIcon from '@mui/icons-material/LocationOffRounded';
import useEnableLocation from "../hooks/useEnableLocation";
import { SaveDraftStatus } from "./SaveDraftStatus";
import { PhoneNumberInput } from "../components/PhoneNumberInput";

const MiddlePanel = styled.div`
  grid-area: MiddlePanel;
  max-width: 740px;
  width: 100%;
  margin: 0 auto;
  padding: 0 7%;
  ${({ type }) =>  type === 'newAccount' && 'padding: 0 9%;'}
`;

const SubmitButton = styled(PrimaryButton)`
  display: flex !important;
  margin: 12px auto !important;
  margin-bottom: 50px !important;
`;

const TagContainer = styled.div`
  background-color: #E8E8E8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 12px;
  margin-top: 8px;
  margin-bottom: 1.6rem;
  position: relative;
`;

const LocationContainer = styled.div`
  background-color: #E8E8E8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 12px;
  margin-top: 8px;
  height: 45px;
  margin-bottom: 1.6rem;
  position: relative;
`;

const TextAreaLimit = styled.div`
  color: ${ THEME_COLORS.primary };
  text-align: right;
  font-size: 0.8rem;
  margin-bottom: 1.4rem;
`;

const TextFormField = styled(TextField)`
    margin-top: 0.5rem !important;
    margin-bottom: 0.9rem !important;
`;

const Subheader = styled.div`
  h3 {
    color: ${ THEME_COLORS.primary };
  }
  margin-bottom: 1.4rem;
  display: flex;
  align-items: center;
  @-webkit-keyframes backandforth {
    0% {
      left:0;
    }
    50% {
      left:58%;
    }
    100%{
      left:0;
    }
  }

  @keyframes backandforth {
    0% {
      left:0;
    }
    50% {
      left:50%;
    }
    100%{
      left:0;
    }
  }
`;

const SubmitButtonContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 2rem;
  max-width: 550px;
`;

const HeaderDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;  
`;

const Arrow = styled(WestRoundedIcon)`
  font-size: 30px !important;
  fill: ${ THEME_COLORS.primary } !important;
  position: relative;
  -webkit-animation: backandforth 2s ease 0s;
  -webkit-animation-iteration-count:infinite;
  animation: backandforth 2s ease 0s;
  animation-iteration-count:infinite;
`;

export function MiddleFormSection({ draftTimestamp, setDraftTimestamp, type, errors, handleFieldChange, handleFieldsChange, handleSubmit, user, userAssets, saving, setOpenModal, setNewTags }) {
    const { loading: locationLoading, handleEnableLocation, locationDisabled } = useEnableLocation(handleFieldsChange);

    return (
        <MiddlePanel type={type}>
            <HeaderDetails>
                <div>
                    {
                        type === 'newAccount' &&
                        <Subheader>
                            <Typography component="h3">
                                Hey, there! Let’s start by completing your profile
                            </Typography>
                            <div style={{position: 'relative'}}>
                                <Arrow/>
                            </div>
                        </Subheader>
                    }
                </div>
                <div>
                    <SaveDraftStatus user={user} draftTimestamp={draftTimestamp} setDraftTimestamp={setDraftTimestamp} />
                </div>
            </HeaderDetails>
            <SubHeader>
                First name*
            </SubHeader>
            <TextFormField
                variant="filled"
                color="warning"
                error={!!errors.firstname}
                helperText={errors.firstname}
                onChange={handleFieldChange('firstname')}
                value={user.firstname}
            />
            <SubHeader>
                Last name*
            </SubHeader>
            <TextFormField
                color="warning"
                id="form-surname"
                error={!!errors.surname}
                helperText={errors.surname}
                variant="filled"
                onChange={handleFieldChange('surname')}
                value={user.surname}
            />
            <SubHeader>
                About me*
            </SubHeader>
            <TextFormField
                id="outlined-multiline-flexible"
                multiline
                color="warning"
                error={!!errors.description}
                helperText={errors.description}
                placeholder="Here is some inspo for you: Hey! Looking to swap for concert or festival tickets (anybody said Beyonce?!), holistic wellness practices, vegan meals & more. DM with your suggestions. Been doing lashes and nails for over 10 years now & I love it!. Also down to sit your doggy or bake you cake!"
                rows={5}
                onChange={handleFieldChange('description')}
                value={user.description}
                variant="filled"
            />
            <TextAreaLimit>
                500 characters or less
            </TextAreaLimit>
            <SubHeader>My pronouns</SubHeader>
            <TagContainer>
                <div>
                    {
                        user.pronouns.map((pronoun) => (
                            <Tag key={`${pronoun}-tag`}>
                                {pronoun}
                            </Tag>
                        ))
                    }
                </div>
                <EditButton onClick={() => setOpenModal('PRONOUNS')}/>
            </TagContainer>
            <ErrorFieldMessage
                message={errors.pronouns}
                isVisible={errors.pronouns}
            />
            <SubHeader>My ethnicity</SubHeader>
            <TagContainer>
                <div>
                    {
                        EthnicityOptions.options
                            .filter(({value}) => user.ethnicity.includes(value))
                            .map(({tag}) => (
                                <Tag key={`${tag}-ethnicity-tag`}>
                                    {tag}
                                </Tag>
                            ))
                    }
                </div>
                <EditButton onClick={() => {
                    setNewTags(user.ethnicity);
                    setOpenModal('ETHNICITIES');
                }}/>
            </TagContainer>
            <ErrorFieldMessage
                message={errors.ethnicity}
                isVisible={errors.ethnicity}
            />
            <FormControl sx={{m: 1, width: '100%', margin: '8px 0'}}>
                <SubHeader variant="body1" gutterBottom>
                    Location*
                </SubHeader>
                <Grid container>
                    <Grid item xs={12} sm={12} lg={12}>
                        {
                            locationDisabled &&
                            <TextFormField
                                variant="filled"
                                color="warning"
                                error={!!errors.displaylocation}
                                helperText={errors.displaylocation}
                                placeholder="Brooklyn, NY, United States"
                                onChange={handleFieldChange('displaylocation')}
                                value={user.displaylocation}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <LocationOffRoundedIcon />
                                        </InputAdornment>,
                                }}
                            />
                        }
                        {
                            !locationDisabled &&
                            <LocationContainer>
                                {user.displaylocation || 'No location'}
                                <EditButton
                                    onClick={handleEnableLocation}
                                    loading={locationLoading}
                                    disabled={locationDisabled}
                                />
                            </LocationContainer>
                        }
                    </Grid>

                </Grid>
            </FormControl>
            <Grid container justifyContent="space-between">
                <Grid item xs={12} sm={12} lg={12}>
                    <SubHeader variant="body1" gutterBottom>
                        Email*
                    </SubHeader>
                    <TextFormField
                        variant="filled"
                        color="warning"
                        error={!!errors.username}
                        disabled
                        helperText={errors.username}
                        onChange={(e) => {
                            handleFieldsChange({
                                email: e.target.value,
                                username: e.target.value,
                            })
                        }}
                        value={user.username}
                    />
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                    <SubHeader gutterBottom>
                        Phone number*
                    </SubHeader>
                    <PhoneNumberInput
                        color="warning"
                        id="form-phone-number"
                        error={!!errors.phone}
                        helperText={errors.phone}
                        variant="filled"
                        onChange={(phone) => {
                            handleFieldsChange({
                                phone,
                            })
                        }}
                        value={user.phone}
                    />
                </Grid>
            </Grid>
            <SubmitButtonContainer>
                <SubmitButton
                    onClick={handleSubmit}
                    disabled={saving}
                >
                    Done
                </SubmitButton>
            </SubmitButtonContainer>
        </MiddlePanel>
    );
}