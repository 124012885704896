import { toast } from 'react-toastify';

export const loginSuccess = () => {
  const toasterType = 'success';
  const toasterMessage = 'You successfully logged in.';

  toast(toasterMessage, { type: toasterType, position: 'top-center' } );
};

export const logoutSuccess = () => {
  const toasterType = 'success';
  const toasterMessage = 'You successfully logged out.';

  toast(toasterMessage, { type: toasterType, position: 'top-center' } );
};

export const genericUpdateError = (error) => {
  const toasterType = 'error';
  toast(error, { type: toasterType, position: 'top-center' } );
};

export const genericSuccess = (msg) => {
  const toasterType = 'success';

  toast(msg, { type: toasterType, position: 'top-center' } );
};

export const signupSuccess = () => {
  const toasterType = 'success';
  const toasterMessage = 'You successfully signed up. Kindly check your email for the verification link to finalize the sign-up process.';

  toast(toasterMessage, { type: toasterType, position: 'top-center' } );
};

export const signupOauthSuccess = () => {
  const toasterType = 'success';
  const toasterMessage = 'You successfully signed up.';

  toast(toasterMessage, { type: toasterType, position: 'top-center' } );
};

export const alreadyLoggedin = () => {
  const toasterType = 'warning';
  const toasterMessage = 'You are already logged in.';

  toast(toasterMessage, { type: toasterType, position: 'top-center' } );
};

export const userNotFound = () => {
  const toasterType = 'warning';
  const toasterMessage = "This user doesn't exist.";

  toast(toasterMessage, { type: toasterType, position: 'top-center' } );
};
export const userValidated = () => {
  const toasterType = 'success';
  const toasterMessage = 'Your account is successfully validated. Please log in.';

  toast(toasterMessage, { type: toasterType, position: 'top-center' } );
};
export const userNotValidated = () => {
  const toasterType = 'warning';
  const toasterMessage = 'The validation link is not valid.';

  toast(toasterMessage, { type: toasterType, position: 'top-center' } );
};

export const invitationNotValidated = () => {
  const toasterType = 'warning';
  const toasterMessage = 'The invitation link is not valid.';

  toast(toasterMessage, { type: toasterType, position: 'top-center' } );
};

export const addAssetsToSuap = () => {
  const toasterType = 'warning';
  const toasterMessage = 'Add assets to your wallet to swap!';

  toast(toasterMessage, { type: toasterType, position: 'top-center' } );
};

export const forgotPasswordSuccess = () => {
  const toasterType = 'success';
  const toasterMessage = 'Your password reset request was sent. You will receive a reset link in you inbox.';

  toast(toasterMessage, { type: toasterType, position: 'top-center' } );
};

export const resetPasswordSuccess = () => {
  const toasterType = 'success';
  const toasterMessage = 'You have successfully reset the password.';

  toast(toasterMessage, { type: toasterType, position: 'top-center' } );
};

export const profileNotCompleted = () => {
  const toasterType = 'warning';
  const toasterMessage =
    'You need to complete your profile in order to access to other profiles';
  toast(toasterMessage, { type: toasterType, position: 'top-center' } );
};

export const userBlockedYou = () => {
  const toasterType = 'warning';
  const toasterMessage = 'You have been blocked by this user.';
  toast(toasterMessage, { type: toasterType, position: 'top-center' } );
};

export const accessDenied = () => {
  const toasterType = 'error';
  const toasterMessage = "You can't access this chat.";
  toast(toasterMessage, { type: toasterType, position: 'top-center' } );
};

export const contactUsSuccessful = () => {
  const toasterType = 'success';
  const toasterMessage = "You successfully contacted WeSuap support team. A support member will reach out to you shortly.";
  toast(toasterMessage, { type: toasterType, position: 'top-center' } );
};

export const updateAssetSuccessful = (type) => {
  const toasterType = 'success';
  const toasterMessage = `You successfully ${type} your asset.`;
  toast(toasterMessage, { type: toasterType, position: 'top-center' } );
};

export const updateProfileSuccess = () => {
  const toasterType = 'success';
  const toasterMessage = `You successfully updated your profile.`;
  toast(toasterMessage, { type: toasterType, position: 'top-center' } );
};

export const followSuccess = (type) => {
  const toasterType = 'success';
  const toasterMessage = `You successfully ${type}.`;
  toast(toasterMessage, { type: toasterType, position: 'top-center' } );
};

export const formValidationError = (error) => {
  const toasterType = 'error';
  toast(error, { type: toasterType, position: 'bottom-center' } );
};