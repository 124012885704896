import React from "react";
import Button from "@mui/material/Button";
import styled from 'styled-components';
import CircularProgress from "@material-ui/core/CircularProgress";

const SecondaryBtn = styled(Button)`
   text-transform: unset !important;
   box-shadow: unset !important;
   border-radius: unset !important;
   font-weight: bold !important;
   font-size: 1.25rem !important;
   margin: 22px 0 !important;
   color: #fff !important;
   padding: 4px !important;
   background-color: ${({ backgroundColor }) => backgroundColor ?? '#A7ADAF'} !important;
`;

export function SecondaryButton({ children, loading, onClick, color }) {
    return (
        <SecondaryBtn
            type="submit"
            variant="contained"
            color="primary"
            backgroundColor={loading ? undefined : color}
            disabled={loading}
            onClick={onClick}
            fullWidth
        >
            { loading ? <CircularProgress color="inherit" size={30} /> : children }
        </SecondaryBtn>
    );
}