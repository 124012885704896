import React, {useCallback} from "react";
import HomeLogo from "../../common/HomeLogo";
import {NavBar} from "../../common/NavBar";
import {ImageCarousel} from "../../common/ImageCarousel";
import Placeholder from "../../../assets/images/placeholderImage.png";
import {Body10, Body12, Header12, Header22} from "../../common/Typography";
import {getCategoryLabelsFromValues, getDeliveryMethodFromValue} from "../../common/utils";
import {Avatar, Grid, Tooltip, Typography} from "@mui/material";
import PrimaryButton from "../../common/PrimaryButton";
import {Ratings} from "../../common/RatingDisplays";
import DescriptiveRating from "../components/DescriptiveRating";
import {ReviewList} from "../../review/reviewList";
import DialogForm from "../../common/DialogForm";
import {AssetForm} from "../components/AssetForm";
import styled from "styled-components";
import PlaceIcon from "@mui/icons-material/LocationOnRounded";
import Link from "@material-ui/core/Link";
import {ShareContentModal} from "../../common/ShareContent/ShareContentModal";

const DISABLED_SWAPP_TOOLTIP = 'In order to swap, complete your profile and create an asset.';

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
`;

const Header = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  min-height: 100px;
  grid-template-columns: 2.2fr 0.6fr 1fr;
  gap: 0;
  grid-auto-flow: row;
  grid-template-areas: "Logo Navigation Navigation";
`;

const HeaderContainer = styled.div`
  min-height: 100px;
`;

const Logo = styled.div`
  grid-area: Logo;
  display: flex;
  align-items: center;
  padding-top: 24px;
  padding-left: 4rem;
`;

const Navigation = styled.div`
  grid-area: Navigation;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-left: 5rem;
  padding-right: 4rem;
`;

const Section = styled.div`
  min-height: 50px;
  max-width: 400px;
  width: 100%;
  ${({ type }) =>  type === 'view' && `
    margin: 0 auto;
    max-width: 640px;
  `}
`;

const Form = styled.div`
  width: 100%;
  display: grid;
  height: 100%;
  padding: 0 4rem;
  grid-template-columns: 1.6fr 0.6fr 1fr 0.6fr 1.2fr;
  gap: 0;
  grid-auto-flow: row;
  grid-template-areas: "LeftPanel LeftPanel RightPanel RightPanel RightPanel";
`;

const LeftPanel = styled.div`
  grid-area: LeftPanel;
  padding: 2rem 2rem 100px 0;
  img {
    width: 100%;
    height: auto;
  }
`;

const RightPanel = styled.div`
  grid-area: RightPanel;
  padding: 2rem 0 100px 0;

  img {
    width: 100%;
    height: auto;
  }
`;

const Tag = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin: 0 4px 4px 4px;
  border-radius: 20px;
  min-width: 100px;
  background-color: #FAFAFA;
`;

const SmallTag = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  padding: 4px 8px;
  margin: 0 4px 4px 4px;
  border-radius: 20px;
  background-color: #FAFAFA;
`;

const RatingCount = styled(Typography)`
  display: inline-block !important;
  font-size: 0.775rem !important;
`;

const DisplayPhotos = styled.div`
  position: relative;
  margin: 0 auto;
  overflow-y: hidden;
  max-height: 585px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    object-fit: cover;
    width: 95%;
    height: auto;
  }
`;

const ActionButtons = styled.div`
  max-width: 250px;
  margin-top: 12px;
  display: flex;
`;

const UserDetails = styled(Grid)`
  display: flex !important;
  justify-content: flex-end !important;
`;

const DetailField = styled(Grid)`
  margin-bottom: 24px !important;
  justify-content: space-between !important;
`;


const AssetHeader = styled(Grid)`
  margin-top: 1.5rem !important;
  margin-bottom: 32px !important;
`;

const Location = styled(Typography)`
  font-size: 0.7rem !important;
  margin-top: 4px !important;
  margin-bottom: 8px !important;
`;

const LocationIcon = styled(PlaceIcon)`
  fill: #717273 !important;
  font-size: 0.9rem !important;
  margin-top: -4px !important;
`;

const UserDescription = styled.div`
  margin-left: 12px;
  color: #717273;
`;

const MinimalLink = styled(Link)`
  color: #4e5152 !important;
  text-decoration: unset !important;
`;

const RatingText = styled(Typography)`
  font-size: 1.55rem !important;
  margin: 0 12px !important;
`;

export function Web({
    openModal,
    categories,
    loadingCategories,
    openAssetForm,
    onClose,
    updateAsset,
    errors,
    updating,
    resetErrors,
    onSwapp,
    userInfo,
    asset,
    userReviews,
    assetReviews,
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleShareContentClick = useCallback((event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }, [anchorEl]);

    return (
        <Container>
            <HeaderContainer>
                <Header>
                    <Logo>
                        <Section>
                            <HomeLogo position="absolute" />
                        </Section>
                    </Logo>
                    <Navigation>
                        <Section>
                            <NavBar condense={true} />
                        </Section>
                    </Navigation>
                </Header>
            </HeaderContainer>
            <Form>
                <LeftPanel>
                    <ImageCarousel
                        animation="slide"
                        interval={8000}
                        stopAutoPlayOnHover={true}
                        indicators={asset.images.length > 1}
                    >
                        {
                            asset.images.map((img, i) => {
                                return (
                                    <DisplayPhotos>
                                        <img src={img || Placeholder} alt={`${asset.title} image ${i + 1}`} />
                                    </DisplayPhotos>
                                );
                            })
                        }
                    </ImageCarousel>
                </LeftPanel>
                <RightPanel>
                    <Body10>
                        {getCategoryLabelsFromValues(categories, [asset.category])[0]}
                    </Body10>
                    <AssetHeader container>
                        <Grid item xs={12} sm={6} lg={6}>
                            <Header22>{asset.title}</Header22>
                            <ActionButtons>
                                {
                                    userInfo.id === asset.user.id &&
                                    <PrimaryButton
                                        variant="contained"
                                        className="condensed"
                                        onClick={() => openAssetForm()}
                                    >
                                        Edit
                                    </PrimaryButton>
                                }
                                {
                                    userInfo.id !== asset.user.id &&
                                    <>
                                        <div>
                                            <PrimaryButton
                                                variant="contained"
                                                className="condensed"
                                                onClick={onSwapp}
                                                sx={{ marginRight: '1rem !important' }}
                                            >
                                                Swap
                                            </PrimaryButton>
                                        </div>
                                        <Tooltip title="Feature coming soon!" arrow placement="bottom">
                                            <PrimaryButton
                                                variant="contained"
                                                className="condensed"
                                            >
                                                Save
                                            </PrimaryButton>
                                        </Tooltip>
                                    </>
                                }
                                <PrimaryButton
                                    variant="contained"
                                    className="condensed"
                                    onClick={handleShareContentClick}
                                    sx={{ marginLeft: '1rem !important' }}
                                >
                                    Share
                                </PrimaryButton>
                            </ActionButtons>
                        </Grid>
                        <UserDetails item xs={12} sm={6} lg={6}>
                            <Avatar alt="user-profile-picture" src={asset.user.profilePicture} />
                            <UserDescription>
                                <MinimalLink href={`/profile/${asset.user.uuid}`}>
                                    <Typography variant="body1">by {asset.user.firstName} {asset.user.lastName}</Typography>
                                </MinimalLink>
                                <Location>
                                    <LocationIcon fontSize="small" />
                                    {asset.user.displaylocation || 'No Location'}
                                </Location>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Ratings name="read-only" readOnly size="medium" value={userReviews.userReviewAverage ?? 0} />
                                    <MinimalLink href={`/profile/${asset.user.id}/reviews`}>
                                        {
                                            userReviews.userReviewTotal > 0 &&
                                            <RatingCount variant="body2">
                                                ({userReviews.userReviewTotal})
                                            </RatingCount>
                                        }
                                    </MinimalLink>
                                </div>
                            </UserDescription>
                        </UserDetails>
                    </AssetHeader>
                    <DetailField container>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                            <Body10 gutterBottom>
                                Most likely to swap for
                            </Body10>
                        </Grid>
                        <Grid item xs={12} sm={12} md={9.5} lg={9.5}>
                            {getCategoryLabelsFromValues(categories, asset.user.topCategories)
                                .map((category) => (
                                    <Tag key={`most-likely-swap-${category}`}>
                                        {category}
                                    </Tag>
                                ))
                            }
                        </Grid>
                    </DetailField>
                    <DetailField container>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                            <Body10 gutterBottom>
                                Description
                            </Body10>
                        </Grid>
                        <Grid item xs={12} sm={12} md={9.5} lg={9.5}>
                            <Body12 sx={{ whiteSpace: 'pre-wrap' }} gutterBottom>
                                {asset.description}
                            </Body12>
                        </Grid>
                    </DetailField>
                    <DetailField container>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                            <Body10 gutterBottom>
                                Delivery methods
                            </Body10>
                        </Grid>
                        <Grid item xs={12} sm={12} md={9.5} lg={9.5}>
                            {asset.deliveryMethods
                                .map((method) => (
                                    <Tag key={`delivery-method-${method}`}>
                                        {getDeliveryMethodFromValue(method)}
                                    </Tag>
                                ))
                            }
                        </Grid>
                    </DetailField>
                    <DetailField container>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                            <Body12 gutterBottom>
                                {asset.type === 'PRODUCT' ? 'Condition' : 'Expertise'}
                            </Body12>
                        </Grid>
                        <Grid item xs={12} sm={12} md={9.5} lg={9.5}>
                            <DescriptiveRating
                                categoryType={asset.type}
                                value={asset.condition}
                                alignment="flex-start"
                                readOnly
                            />
                        </Grid>
                    </DetailField>
                    {asset.customizable &&
                        <DetailField container>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                <Body10 gutterBottom>
                                    Customization
                                </Body10>
                            </Grid>
                            <Grid item xs={12} sm={12} md={9.5} lg={9.5}>
                                <Body12>
                                    Open to customize
                                </Body12>
                            </Grid>
                        </DetailField>
                    }
                    <DetailField container>
                        <Grid item xs={12} sm={12} md={2} lg={2}/>
                        <Grid item xs={12} sm={12} md={9.5} lg={9.5}>
                            {getCategoryLabelsFromValues(categories, asset.categories)
                                .map((category) => (
                                    <SmallTag key={`tags-${category}`}>
                                        #{category}
                                    </SmallTag>
                                ))
                            }
                        </Grid>
                    </DetailField>
                    <Grid container>
                        <Grid item xs={12} sm={12} lg={12}>
                            <Header12 gutterBottom>Ratings:</Header12>
                        </Grid>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
                            {
                                assetReviews.reviews.length === 0 &&
                                'No ratings yet.'
                            }
                            {
                                assetReviews.reviews.length > 0 &&
                                <Ratings
                                    name="read-only"
                                    readOnly
                                    size="large"
                                    precision={0.5}
                                    value={assetReviews.assetReviewAverage ?? 0}
                                />
                            }
                            {
                                assetReviews.reviews.length > 0 &&
                                <RatingText>
                                    {assetReviews.assetReviewAverage} out of 5
                                </RatingText>
                            }
                        </div>
                        <ReviewList reviews={assetReviews} type="asset" />
                    </Grid>
                </RightPanel>
            </Form>
            <ShareContentModal
                anchorEl={anchorEl}
                title={`Check this out on WeSuap - ${asset.title}`}
                url={`${process.env.REACT_APP_PUBLIC_URL}/assets/${asset.uuid}`}
                postMainImage={asset.postMainImage}
                onClose={() => setAnchorEl(null)}
                keepOpen={() => setAnchorEl(anchorEl)}
            />
            {openModal === 'ASSET_FORM' &&
                <DialogForm
                    label=""
                    description=""
                    onClose={() => {
                        onClose();
                        resetErrors();
                    }}
                    closePosition="right"
                >
                    <AssetForm
                        onClose={() => {
                            onClose();
                            resetErrors();
                        }}
                        updateAsset={updateAsset}
                        errors={errors}
                        updating={updating}
                        uuid={asset.uuid}
                    />
                </DialogForm>
            }
        </Container>
    );
}