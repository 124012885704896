import * as React from 'react';
import styled from "styled-components";
import { styled as muiStyled } from '@mui/material/styles';
import MuiDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import PrimaryButton from "./PrimaryButton";
import { Body10, Header18 } from "./Typography";
import { THEME_COLORS } from "./colors";
import SecondaryGrayButton from "./SecondaryGrayButton";
import {Box} from "@mui/material";

const CloseButton = styled(SecondaryGrayButton)`
    margin-right: 4px !important;
`;

const ButtonGroup = styled(DialogActions)`
    max-width: 370px !important;
    margin-bottom: 1rem !important;
    gap: 3rem !important; 
    width: 60% !important; 
    padding: 0 20px !important; 
    
`;

const StyledDialog = muiStyled(MuiDialog)(({ theme, backgroundcolor, width, maxWidth, isMobile }) => ({
    '& .MuiPaper-root': {
        borderRadius: isMobile ? 0 : '36px', 
        border: isMobile ? 'none' : '4px solid #D9D9D9',
        overflowY: 'auto',
        width: isMobile ? '100%' : width,
        maxWidth: isMobile ? '100%' : maxWidth,
    },
    '& .MuiDialogContent-root': {
        backgroundColor: backgroundcolor === 'subdued' ? '#F4F5F4' : '#FFFFFF',
        border: 'unset',
    },
    '& .MuiDialogTitle-root': {
        textAlign: 'center',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
        marginTop: isMobile ? '1rem' : undefined,
        marginBottom: 12,
        width: isMobile ? '100%' : '60%',
    },
}));

const Description = styled(Body10)`
  color: ${THEME_COLORS.primary};
  text-align: center;
  margin-bottom: 1rem;
`;

export default function DialogForm({
   children,
   disabled,
   theme,
   radius,
   buttonLabel,
   closeLabel,
   isOpen,
   width,
   maxWidth,
   label,
   description,
   onClose,
   onCancel,
   onSave,
   isMobile,
}) {

    const handleClose = (event, reason) => {
        if (reason === "backdropClick") return; 
        if (onClose) onClose(event); 
    };

    
    return (
        <StyledDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={!!isOpen || isOpen === undefined}
            width={width ?? 540}
            maxWidth={maxWidth ?? 700}
            backgroundcolor={theme}
            borderradius={radius}
            isMobile={isMobile}
            fullScreen={isMobile}
        >

            {label && (
                <>
                    <Header18
                        id="customized-dialog-title"
                        sx={{
                            textAlign: 'center !important',
                            marginBottom: '1rem !important',
                            marginTop: { xs: '4rem !important', sm: '1.8rem !important' },
                        }}
                    >
                        {label}
                    </Header18>
                    {description && (
                        <Description>
                            {description}
                        </Description>
                    )}
                </>
            )}
            <Box
                sx={{
                    marginTop: { xs: '3rem !important', sm: '0.8rem !important' },
                }}
            >
                {children}
            </Box>


            {(onSave || onCancel) &&
                <ButtonGroup>
                   {onCancel &&
                        <CloseButton onClick={onCancel}>
                            {closeLabel || 'Cancel'}
                        </CloseButton>
                    }
                    {onSave &&
                        <PrimaryButton autoFocus disabled={disabled} onClick={onSave}>
                        {buttonLabel || 'Done'}
                        </PrimaryButton>
                    }
                </ButtonGroup>
            }
        </StyledDialog>
    );
}