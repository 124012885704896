import styled from "styled-components";
import {Typography} from "@mui/material";
import {Body10, Header14} from "../common/Typography";
import React, {useCallback, useState} from "react";
import PlaceIcon from '@mui/icons-material/LocationOnRounded';
import {SuggestFollowUser} from "../follow/components/SuggestFollowUser";
import {useFollow} from "../follow/useFollow";
import Searchbar from "../common/Searchbar";

const LeftPanel = styled.div`
  grid-area: LeftPanel;
  background-color: #F4F5F4;
  padding: 0 2rem;
  padding-bottom: 100px;
`;

const SuggestionContainer = styled.div`
  margin-top: 2rem;
`;

const Location = styled(Typography)`
  margin-top: 4px !important;
  margin-bottom: 8px !important;
  color: #717273 !important;
`;

const LocationIcon = styled(PlaceIcon)`
  fill: #717273 !important;
  font-size: 1.25rem !important;
  margin-top: -4px !important;
  margin-right: 2px !important;
`;

export function LeftSection({
    user,
    suggestedFollowingUsers,
    searchedUsers,
    onSearchQuery,
    searchLoaded,
}) {
    const [isFollowingList, setIsFollowingList] = useState([]);

    const callback = useCallback((id) => {
        setIsFollowingList([...isFollowingList, id]);
    }, [isFollowingList, setIsFollowingList]);

    const { follow, loading, unfollow } = useFollow(callback);

    const userList = searchLoaded && searchedUsers ? searchedUsers : suggestedFollowingUsers;

    return (
        <LeftPanel>
            <div>
                <Location>
                    <LocationIcon fontSize="medium" />
                    {user.displaylocation || 'No Location'}
                </Location>
            </div>
            <SuggestionContainer>
                <div style={{ marginBottom: '2rem' }}>
                    <Searchbar onSearch={onSearchQuery} />
                </div>
                <Header14>Suggested for you</Header14>
                {
                    userList.map((user) => (
                        <SuggestFollowUser
                            key={`suggested-user-${user.id}`}
                            user={user}
                            follow={follow}
                            unfollow={unfollow}
                            isFollowingList={isFollowingList}
                        />
                    ))
                }
            </SuggestionContainer>
        </LeftPanel>
    );
}