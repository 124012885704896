import React, {useContext, useState} from "react";

import {Avatar, Button, Grid, Typography} from "@mui/material";
import styled from "styled-components";
import PlaceIcon from '@mui/icons-material/LocationOnRounded';
import Link from "@material-ui/core/Link";
import PrimaryButton from "../../common/PrimaryButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import useCreateChat from "../../assets/hooks/useCreateChat";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import useGetCategories from "../../common/hooks/useGetCategories";
import { getCategoryLabelsFromValues } from "../../common/utils";
import {THEME_COLORS} from "../../common/colors";
import {AuthContext} from "../../app/AuthContext";
import {Header12} from "../../common/Typography";

const FollowerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const UserActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const UserDetails = styled.div`
  ${({ isMobile }) => !isMobile && `
    margin-top: 1.5rem !important;
    margin-right: 0.4rem !important;
  `}
  display: flex;
  align-items: center;
`;

const Location = styled(Typography)`
  font-size: 0.7rem !important;
  margin-top: 4px !important;
  margin-bottom: 8px !important;
  display: flex !important;
  align-items: center !important;
`;

const Username = styled(Typography)`
  font-weight: 700 !important;
`;

const LocationIcon = styled(PlaceIcon)`
  fill: #717273 !important;
  font-size: 1rem !important;
`;

const UserDescription = styled(Grid)`
  color: #717273;
`;

const MinimalLink = styled(Link)`
  color: #4e5152 !important;
  text-decoration: unset !important;
`;

const UserProfile = styled(Avatar)`
  width: 54px !important;
  height: 54px !important;
  margin-right: 1rem !important;
`;

const MenuContainer = styled(IconButton)`
  padding: 0;
`;

const StackTags = styled(Stack)`
  display: inline-flex !important;
  flex-wrap: wrap;
  row-gap: 0.5rem;
`;

const TruncateButton = styled(Button)`
  text-transform: capitalize !important;
  color: ${THEME_COLORS.subdued} !important;
  justify-content: flex-start !important;
  font-size: 0.8rem !important;
  font-weight: 300 !important;
  padding-right: 0 !important;
  float: right !important;
  &:hover {
    background-color: unset !important;
  }
`;

function getMenuActions(history, userUuid, userId, alreadyFollows, onFollow, onUnfollow, handleSwapp, isProfileActive) {
    return [
        {
            label: 'Swap',
            ...(isProfileActive ? {
                action: handleSwapp,
            } : {
                action: null,
                type: 'disabled',
                description: 'Add assets to swap.'
            }),


        },
        {
            label: 'View profile',
            action: () => history.push(`/profile/${userUuid}`),
        }, {
            label: alreadyFollows ? 'Unfollow' : 'Follow',
            action: alreadyFollows ? onUnfollow: onFollow,
        }, {
            label: 'Help',
            action: () => history.push('/contact-us'),
        },
    ]
}

function UserLookingFor({ topCategories }) {
    const { categories } = useGetCategories();
    const [showAllLookingForCategories, setShowAllLookingForCategories] = useState(false);
    const categoryOptions = getCategoryLabelsFromValues(categories, topCategories);

    return (
        <div style={{ display: 'flex', gap: 8, alignItems: 'center', marginTop: '0.8rem !important' }}>
        <Header12>Looking For:</Header12>
        <StackTags direction="row" spacing={1}>
            {
                (showAllLookingForCategories ? categoryOptions : categoryOptions.slice(0, 4)).map((option) =>
                    <Chip label={option} size="small" />
                )
            }
            {
                categoryOptions.length > 4 &&
                !showAllLookingForCategories &&
                <TruncateButton
                    size="small"
                    onClick={() => setShowAllLookingForCategories(!showAllLookingForCategories)}
                >
                    More
                </TruncateButton>
            }
        </StackTags>
        </div>
    );
}

export function FollowUserHeader({ post, title, currentUser, user, follow, unfollow, isFollowing, isMobile, onOpenMenuAction }) {
    const { isProfileActive } = useContext(AuthContext);
    const history = useHistory();
    const [alreadyFollows, setAlreadyFollows] = useState(isFollowing);

    const { initiateSwap, loading } = useCreateChat(history);

    const handleSwapp = () => {
        if (post.serialId) {
            history.push(`/chat/${post.serialId}`);
        } else {
            const predefinedMessage = '[PREDEFINED]Say hi and edit your offer above';
            initiateSwap({
                predefinedMessage,
                ownerId: post.user.id,
                assetId: post.assetId,
            });
        }
    }

    const redirectToAsset = () => {
        window.location = '/my-assets?message=add_assets_to_swap';
    }

    const swapMethod = isProfileActive ? handleSwapp : redirectToAsset;

    const onFollow = () => {
        follow(user.id, () => setAlreadyFollows(true));
    }

    const onUnfollow = () => {
        unfollow(user.id, () => setAlreadyFollows(false));
    }

    return (
        <div>
            <FollowerContainer>
                <UserDetails isMobile={isMobile}>
                    <MinimalLink href={`/profile/${user.uuid}`}>
                        <UserProfile alt="user-profile-picture" src={user.profilePicture} />
                    </MinimalLink>
                    <UserDescription>
                        <MinimalLink href={`/assets/${post.assetUuid}`}>
                            {title && <Username variant="body1">{title}</Username>}
                            {!title && <Username variant="body1">by {user.firstName} {user.lastName}</Username>}
                        </MinimalLink>
                        <Location>
                            <LocationIcon />
                            {user.displaylocation || 'No Location'}
                        </Location>
                    </UserDescription>
                </UserDetails>
                {
                    currentUser.id !== user.id && !isMobile &&
                    <UserActions>
                        <PrimaryButton
                            onClick={() => swapMethod()}
                            sx={{marginRight: '0.5rem !important'}}
                        >
                            Swap
                        </PrimaryButton>
                        {!alreadyFollows &&
                            <PrimaryButton
                                onClick={onFollow}
                            >
                                Follow
                            </PrimaryButton>
                        }
                        {alreadyFollows &&
                            <PrimaryButton
                                onClick={onUnfollow}
                            >
                                Unfollow
                            </PrimaryButton>
                        }
                    </UserActions>
                }
                {
                    currentUser.id !== user.id && isMobile && onOpenMenuAction &&
                    <MenuContainer
                        onClick={() => onOpenMenuAction(
                            getMenuActions(history, user.uuid, user.id, alreadyFollows, onFollow, onUnfollow, swapMethod, isProfileActive))}
                        type="button"
                        aria-label="active-menu-action"
                    >
                        <MoreVertIcon />
                    </MenuContainer>
                }
            </FollowerContainer>
            <UserLookingFor topCategories={user.topCategories} />
        </div>
    );
}