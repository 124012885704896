import React, {useState} from 'react';
import {Box, Rating, Grid, Typography} from '@mui/material';
import Placeholder from '../../assets/images/placeholderImage.png';
import { NavBar } from '../common/NavBar';
import PrimaryButton from "../common/PrimaryButton";
import {getPronounDisplay} from "./utils";
import {getCategoryLabelsFromAssets, getCategoryLabelsFromValues} from "../common/utils";
import useFetchUserAssets from "../assets/hooks/useFetchUserAssets";
import HomeLogo from "../common/HomeLogo";
import Paper from "@mui/material/Paper";
import {
    ActionButtons,
    AssetList,
    LocationIcon,
    Location,
    MiddlePanel,
    PanelHeader,
    PanelSection,
    ProfilePicture,
    SidePanel,
    Tag,
    UserCategoryList,
    UserDisplayName, TruncateButton, MinimalLink
} from "./styledComponents";
import styled from "styled-components";
import useSwapContract from "./hooks/useSwapContract";
import useFetchProfile from "./hooks/useFetchProfile";
import {THEME_COLORS} from "../common/colors";
import {Add as AddIcon, Remove as RemoveIcon} from "@material-ui/icons";
import useFetchReviews from "../review/useFetchReviews";
import { Ratings } from "../common/RatingDisplays";
import {ReviewList} from "../review/reviewList";
import useGetCategories from "../common/hooks/useGetCategories";


const DEFAULT_MAX_CATEGORIES_DISPLAY = 12;


const MiddlePanelHeader = styled(PanelHeader)`
  text-align: left !important;
  margin-top: 24px !important;
`;

const Advertisement = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  background-color: #FEFEFE;
  color: #AAB1A9;
  min-height: 200px;
`;

const SavingText = styled(Typography)`
  font-size: 1.75rem !important;
`;

const currentFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});


const ShowAllBtn = styled.div`
  display: inline-block;
  color: ${THEME_COLORS.primary};
  font-weight: bold;
  cursor: pointer;
`;

const SubHeader = styled(Typography)`
  font-weight: bold !important;
  color: #4E5152 !important;
  margin-top: 12px !important;
`;

const RatingText = styled(Typography)`
  font-size: 1.25rem !important;
  margin: 0 12px !important;
`;

export default function PublicUserReviewList(props) {
    const { match, history } = props;

    const { userInfo: user } = useFetchProfile({ id: match.params.username });
    const { categories, loading: loadingCategories } = useGetCategories();
    const [showAllCategories, setShowAllCategories] = useState(false);
    const { userAssets } = useFetchUserAssets(user);
    const { swappMetrics } = useSwapContract(user.uuid);
    const { reviews } = useFetchReviews('user', match.params.username);

    const userLookingForList = getCategoryLabelsFromValues(categories, user.topcategories);

    const userLookingForListDisplay = showAllCategories ? userLookingForList : userLookingForList.slice(0, DEFAULT_MAX_CATEGORIES_DISPLAY);

    return (
        <Box style={{ height: '100vh' }}>
            <Grid container style={{ height: '100%' }}>
                <SidePanel item xs={12} sm={4} lg={3}>
                    <HomeLogo />
                    <ProfilePicture
                        variant="rounded"
                        src={user.profilepicture || Placeholder}
                        sx={{ width: '100%', height: 'auto', borderRadius: '20px' }}
                    />
                    <div>
                        <PanelSection>
                            <SubHeader>Ratings:</SubHeader>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Ratings
                                    name="read-only"
                                    readOnly
                                    size="large"
                                    precision={0.5}
                                    value={reviews.userReviewAverage ?? 0}
                                />
                                {
                                    reviews.reviews.length &&
                                    <RatingText>
                                        {reviews.userReviewAverage} out of 5
                                    </RatingText>
                                }
                            </div>
                        </PanelSection>
                    </div>
                </SidePanel>
                <MiddlePanel item xs={12} sm={4} lg={6}>
                    <NavBar />
                    <UserDisplayName variant="h4">
                        {user.firstname} {user.surname} ({getPronounDisplay(user.pronouns)})
                    </UserDisplayName>
                    <UserCategoryList variant="h6" gutterBottom>
                        {getCategoryLabelsFromAssets(categories, userAssets).join(' | ')}
                    </UserCategoryList>
                    <Location variant="body2" gutterBottom>
                        <LocationIcon fontSize="small" />
                        {user.displaylocation || 'No Location'}
                    </Location>
                    <ActionButtons>
                        <PrimaryButton
                            variant="contained"
                            className="condensed"
                            onClick={() => history.push(`/user-assets/${user.id}`)}
                        >
                            Wallet
                        </PrimaryButton>
                        {false && <PrimaryButton
                            variant="contained"
                            className="condensed"
                            onClick={() => history.push('/profile/edit')}
                        >
                            Follow
                        </PrimaryButton>}
                    </ActionButtons>
                    <MiddlePanelHeader variant="h6" gutterBottom>
                        <SubHeader>
                            Reviews:
                        </SubHeader>
                    </MiddlePanelHeader>
                    <ReviewList reviews={reviews} type="user" />
                </MiddlePanel>
                <SidePanel item xs={12} sm={4} lg={3}>
                    <PanelHeader variant="h6" gutterBottom>
                        <SubHeader>
                            {user.firstname}’s Top Picks to Swap
                        </SubHeader>
                    </PanelHeader>
                    <AssetList>
                        {
                            userAssets.length > 0 ? userAssets.map((asset) => (
                                <div
                                    key={`asset-list-${asset.category}`}
                                    style={{ margin: 4, maxWidth: 140, cursor: 'pointer' }}
                                    onClick={() => history.push(`/assets/${asset.id}`)}
                                >
                                    <Paper style={{ margin: '8px auto', width: 95 }}>
                                        <img alt="placeholder" src={asset.mainImage} />
                                    </Paper>
                                    <Typography variant="body2">{asset.title}</Typography>
                                </div>
                            )) : 'User currently have no assets.'
                        }
                    </AssetList>
                    <Advertisement>
                        Advertisement
                    </Advertisement>
                </SidePanel>
            </Grid>
        </Box>
    );
}
