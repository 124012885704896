import React, { useCallback, useState } from "react";
import { FixBannerPageLayout } from "../common/Mobile/FixBannerPageLayout";
import {MiddleSection} from "./MiddleSection";
import {useFollow} from "../follow/useFollow";
import styled from "styled-components";
import { SuggestFollowUserMobile } from "../follow/components/SuggestFollowUserMobile";
import ActionMenuModal from "../common/Mobile/ActionMenuModal";
import {Header14} from "../common/Typography";
import {Typography} from "@mui/material";
import PlaceIcon from "@mui/icons-material/LocationOnRounded";
import Searchbar from "../common/Searchbar";

const SuggestionContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  gap: 1rem;
  overflow-x: auto;
  margin-bottom: 1.5rem;
`;

const Location = styled(Typography)`
  margin-top: 4px !important;
  margin-bottom: 8px !important;
  color: #717273 !important;
`;

const LocationIcon = styled(PlaceIcon)`
  fill: #717273 !important;
  font-size: 1.25rem !important;
  margin-top: -4px !important;
  margin-right: 2px !important;
`;

export function DiscoveryPageMobile({
    user,
    suggestedFollowingUsers,
    discoveryContent,
    searchedUsers,
    onSearchQuery,
    searchLoaded,
}) {
    const [isFollowingList, setIsFollowingList] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [menuActions, setMenuActions] = useState([]);
    const userList = searchLoaded && searchedUsers ? searchedUsers : suggestedFollowingUsers;

    const handleOpenMenuAction = (options) => {
        setMenuActions(options);
        setOpenModal(true);
    };

    const callback = useCallback((id) => {
        setIsFollowingList([...isFollowingList, id]);
    }, [isFollowingList, setIsFollowingList]);

    const { follow, loading, unfollow } = useFollow(callback);

    return (
         <FixBannerPageLayout>
             <div style={{ textAlign: 'center' }}>
                 <Location>
                     <LocationIcon fontSize="medium" />
                     {user.displaylocation || 'No Location'}
                 </Location>
             </div>
             <Searchbar onSearch={onSearchQuery} />
             <SuggestionContainer>
                 {
                     userList.map((user) => (
                         <SuggestFollowUserMobile
                             key={`suggested-user-${user.id}`}
                             user={user}
                             follow={follow}
                             unfollow={unfollow}
                             isFollowingList={isFollowingList}
                             onOpenMenuAction={handleOpenMenuAction}
                         />
                     ))
                 }
             </SuggestionContainer>
             <MiddleSection
                 user={user}
                 discoveryContent={discoveryContent}
                 isMobile={true}
             />
             {
                 openModal &&
                 <ActionMenuModal
                     options={menuActions}
                     onClose={() => {
                         setOpenModal(false);
                         setMenuActions([]);
                     }}
                 />
             }
         </FixBannerPageLayout>
    );
}