import React from "react";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import ChatRoom from "../chatRoom";

const MiddlePanel = styled.div`
  ${({ isMobile }) => !isMobile && `
    grid-area: MiddlePanel;
    padding: 0 7%;
    width: 100%;
    margin: 0 auto;
    max-width: 800px;
    padding-bottom: 100px;
  `}
`;

const CenterContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
`;

export function MiddleSection({
      userInfo,
      chatDetails,
      chatLoading,
      onUpdateMessageRead,
      fetchChatDetailsBySerialId,
      isMobile,
      format,
}) {
    return (
        <MiddlePanel isMobile={isMobile}>
            {!chatDetails.id && !chatLoading &&
                <CenterContainer>
                    Select a conversation
                </CenterContainer>
            }
            {!chatDetails.id && chatLoading &&
                <CenterContainer>
                    <CircularProgress color="error" size={60} />
                </CenterContainer>
            }
            {
                chatDetails.id &&
                <ChatRoom
                    chatDetails={chatDetails}
                    currentUserId={userInfo.id}
                    onUpdateMessageRead={onUpdateMessageRead}
                    fetchChatDetailsBySerialId={fetchChatDetailsBySerialId}
                    isMobile={isMobile}
                    format={format}
                />
            }
        </MiddlePanel>
    );
}