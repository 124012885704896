import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopyRounded';
import CheckIcon from '@mui/icons-material/CheckRounded';

import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    PinterestIcon,
    PinterestShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TumblrIcon,
    TumblrShareButton,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    XIcon,
} from "react-share";
import styled from "styled-components";
import { useState } from "react";
import { Box } from "@mui/material";
import { autoCopy } from "../utils/autoCopy";

const webStyle = {
    backgroundColor: '#FFF',
    marginTop: '0.5rem',
    padding: '1.8rem',
    borderRadius: '1.5rem',
    maxWidth: 300,
    width: 300,
    position: 'relative',
    gap: '0.4rem',
    boxShadow: '4px 4px 14px #515050',
};

const mobileStyle = {
    gap: '0.8rem',
    padding: '2rem',
};

const CopyButton = styled(IconButton)`
    background-color: #7F7F7F !important;
    svg {
        fill: #FFF !important;
        width: ${({ isMobile }) => isMobile ? 1.65 : 0.85}em !important;
        height: ${({ isMobile }) => isMobile ? 1.65 : 0.85}em !important;
    }
`;

export function ShareContent({
     title = 'Check out this on WeSuap.',
     shareUrl,
     onClose,
     shareImagePreview = '',
    isMobile,
}) {
    const [copySuccess, setCopySuccess] = useState(false);
    const iconSize = isMobile ? 48 : 32;

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                zIndex: (theme) => theme.zIndex.drawer + 5,
                ...(isMobile ? mobileStyle : webStyle),
            }}
        >
            <div>
                <TwitterShareButton
                    url={shareUrl}
                    title={title}
                    onClick={onClose}
                >
                    <XIcon size={iconSize} round/>
                </TwitterShareButton>
            </div>
            <div>
                <RedditShareButton
                    url={shareUrl}
                    title={title}
                    windowWidth={660}
                    windowHeight={460}
                    onClick={onClose}
                >
                    <RedditIcon size={iconSize} round/>
                </RedditShareButton>
            </div>
            {
                shareImagePreview &&
                <div>
                    <PinterestShareButton
                        url={shareUrl}
                        media={shareImagePreview}
                        onClick={onClose}
                    >
                        <PinterestIcon size={iconSize} round/>
                    </PinterestShareButton>
                </div>
            }
            <div>
                <TumblrShareButton
                    url={shareUrl}
                    title={title}
                    onClick={onClose}
                >
                    <TumblrIcon size={iconSize} round/>
                </TumblrShareButton>
            </div>
            <div>
                <FacebookShareButton
                    url={shareUrl}
                    onClick={onClose}
                >
                    <FacebookIcon size={iconSize} round/>
                </FacebookShareButton>
            </div>
            <div>
                <LinkedinShareButton
                    url={shareUrl}
                    onClick={onClose}
                >
                    <LinkedinIcon size={iconSize} round/>
                </LinkedinShareButton>
            </div>
            {
                process.env.REACT_APP_FACEBOOK_API_KEY &&
                <div>
                    <FacebookMessengerShareButton
                        url={shareUrl}
                        appId={process.env.REACT_APP_FACEBOOK_API_KEY ?? ''}
                        onClick={onClose}
                    >
                        <FacebookMessengerIcon size={iconSize} round/>
                    </FacebookMessengerShareButton>
                </div>
            }
            <div>
                <TelegramShareButton
                    url={shareUrl}
                    title={title}
                    onClick={onClose}
                >
                    <TelegramIcon size={iconSize} round/>
                </TelegramShareButton>
            </div>
            <div>
                <WhatsappShareButton
                    url={shareUrl}
                    title={title}
                    separator=":: "
                    onClick={onClose}
                >
                    <WhatsappIcon size={iconSize} round/>
                </WhatsappShareButton>
            </div>
            <div>
                <EmailShareButton
                    url={shareUrl}
                    subject={title}
                    body="body"
                    onClick={onClose}
                >
                    <EmailIcon size={iconSize} round/>
                </EmailShareButton>
            </div>
            <div>
                <CopyButton
                    aria-label="copy-share-content"
                    onClick={() => autoCopy(shareUrl, setCopySuccess)}
                    disabled={copySuccess}
                    isMobile={isMobile}
                >
                    {copySuccess ? <CheckIcon/> : <ContentCopyIcon/>}
                </CopyButton>
            </div>
        </Box>
);
}