import React, {useState} from 'react';
import { fromApi } from "../../common/models/user";
import useFetchUserAssets from "../../assets/hooks/useFetchUserAssets";
import { LeftSection } from "./LeftSection";
import { ProfileLayout } from "../components/ProfileLayout";
import { MiddleSection } from "./MiddleSection";
import { RightSection } from "./RightSection";
import useGetCategories from "../../common/hooks/useGetCategories";
import useSwapContract from "../hooks/useSwapContract";
import {getCategoryLabelsFromValues} from "../../common/utils";
import useFetchProfile from "../hooks/useFetchProfile";
import {MobileProfilePage} from "./MobileProfilePage";
import FORMAT_SIZES from "../../common/constants/FormatSizes";

const DEFAULT_MAX_CATEGORIES_DISPLAY = 8;

export default function ProfileView(props) {
    const { format, match, userInfo } = props;
    const { userInfo: user } = useFetchProfile({ uuid: match.params.username });
    const { userAssets } = useFetchUserAssets(user);
    const { categories, loading: loadingCategories } = useGetCategories();
    const isCurrentUser = userInfo.id === user.id;

    const { contractDetails, swappMetrics } = useSwapContract(user.uuid);

    const userLookingForList = getCategoryLabelsFromValues(categories, user.topcategories);

    if (format === FORMAT_SIZES.MOBILE) {
        return (
            <MobileProfilePage
                swappMetrics={swappMetrics}
                type="public"
                user={user}
                userLookingForList={userLookingForList}
                contractDetails={contractDetails}
                categories={categories}
                userAssets={userAssets}
                isCurrentUser={isCurrentUser}
                userInfo={userInfo}
            />
        );
    }

    return (
        <ProfileLayout type="view" rightPanelHeader="Fav assets to Swap">
            <LeftSection
                type="public"
                user={user}
                isCurrentUser={isCurrentUser}
                swappMetrics={swappMetrics}
                categories={categories}
                userLookingForList={userLookingForList}
            />
            <MiddleSection
                type="public"
                user={user}
                isCurrentUser={isCurrentUser}
                categories={categories}
                userLookingForList={userLookingForList}
                userInfo={userInfo}
            />
            <RightSection
                contractDetails={contractDetails}
                type="public"
                user={user}
                userAssets={userAssets}
            />
        </ProfileLayout>
    );
}
