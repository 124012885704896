export function predefineFirstPersonContact(otherUser, currentUser) {
    return `[PREDEFINED]Say hi and edit your offer above.`
}

export function predefineFirstPersonEditing(otherUser, currentUser) {
    return `[PREDEFINED]${currentUser} has made an edit to their offer! ${otherUser}, please review and submit yours.`;
}

export function predefineSecondPersonEditing(otherUser, currentUser) {
    return `[PREDEFINED]Both offers are up. You are now ready to review and approve.`;
}

export function predefineFirstApproves(otherUser, currentUser) {
    return `[PREDEFINED]${currentUser}, it's a SWAP! ${currentUser}, make sure to complete it before the date you agreed on and to leave a review.`;
}

export function predefineSecondApproves(otherUser, currentUser) {
    return `[PREDEFINED]You are both set. Both offers have been approved. Make sure to complete before the data you agreed on.`;
}

export function predefineCompleteSwapp(currentUser) {
    return `[PREDEFINED]${ currentUser } has completed the swap. Don't forget to leave a review.`;
}

export function predefineDeniedSwapp(otherUser, currentUser) {
    return `[PREDEFINED]${ currentUser } declined your offer, please try again with a different offer.`;
}