import React from "react";
import Divider from "@mui/material/Divider";
import styled from "styled-components";
import { LoginSocialApple, LoginSocialGoogle } from "reactjs-social-login";
import { AppleLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import Typography from "@mui/material/Typography";
import {MinimalLink} from "../../profile/styledComponents";

const AuthButtons = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: 24px auto;
    gap: 16px;
`;

const AuthContainer = styled.div`
    margin-bottom: 24px;
`;

export function AuthCredentialButtons({ onAuthSubmit, action }) {
    return (
        <AuthContainer>
            <Divider>OR</Divider>
            <AuthButtons>
                {false && <LoginSocialApple
                    client_id={process.env.REACT_APP_APPLE_ID || ''}
                    scope={'name email'}
                    redirect_uri={process.env.REACT_APP_LOGIN_REDIRECT_URI}
                    onResolve={({provider, data}) => {
                        console.log('provider', provider)
                        console.log('data', data)
                    }}
                    onReject={err => {
                        console.log(err);
                    }}
                >
                    <AppleLoginButton/>
                </LoginSocialApple>}
                <LoginSocialGoogle
                    client_id={process.env.REACT_APP_GG_APP_ID || ''}
                    redirect_uri={process.env.REACT_APP_LOGIN_REDIRECT_URI}
                    typeResponse="idToken"
                    scope="openid profile email"
                    ux_mode="popup"
                    onResolve={({ data }) => {
                        if (data) {
                            onAuthSubmit({
                                firstname: data.given_name,
                                surname: data.family_name,
                                username: data.email,
                                email: data.email,
                                password: data.credential,
                                profilepicture: data.picture,
                                isOAuth: true,
                            })
                        }
                    }}
                    onReject={err => {
                        console.log(err);
                    }}
                >
                    <GoogleLoginButton text={`${action} with Google`} align="center" />
                </LoginSocialGoogle>
            </AuthButtons>
            <Typography component="span" variant="body2">
                By clicking {action} with Google, you agree to WeSuap's{' '}
                <MinimalLink href="/terms-and-conditions" target="_blank" variant="body2">
                    <b>Terms & conditions, and Privacy Policy</b>
                </MinimalLink>
            </Typography>
        </AuthContainer>
    );
}