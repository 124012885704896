import React, {useState} from "react";
import styled from "styled-components";
import { FixBannerPageLayout } from "../../common/Mobile/FixBannerPageLayout";
import Placeholder from "../../../assets/images/placeholderImage.png";
import {ImageCarousel} from "../../common/ImageCarousel";
import {Body10, Body12, Header18} from "../../common/Typography";
import {getCategoryLabelsFromValues, getDeliveryMethodFromValue} from "../../common/utils";
import {Avatar, Button, Grid, Tooltip, Typography} from "@mui/material";
import {Ratings} from "../../common/RatingDisplays";
import PlaceIcon from "@mui/icons-material/LocationOnRounded";
import Link from "@material-ui/core/Link";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {THEME_COLORS} from "../../common/colors";
import {styled as styledMUI} from "@mui/material/styles";
import ActionMenuModal from "../../common/Mobile/ActionMenuModal";
import DialogForm from "../../common/DialogForm";
import {AssetForm} from "../components/AssetForm";
import {ContentSection} from "../../common/Mobile/ContentSection";
import {SubHeader} from "../../profile/viewProfile/styles";
import DescriptiveRating from "../components/DescriptiveRating";
import {ReviewList} from "../../review/reviewList";
import {ShareContentModal} from "../../common/ShareContent/ShareContentModal";

const DISABLED_SWAPP_TOOLTIP = 'In order to swap, complete your profile and create an asset.';

const Tag = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin: 0 4px 4px 4px;
  border-radius: 20px;
  min-width: 100px;
  background-color: #FAFAFA;
`;

const SmallTag = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  padding: 4px 8px;
  margin: 0 4px 4px 4px;
  border-radius: 20px;
  background-color: #FAFAFA;
`;

const RatingCount = styled(Typography)`
  display: inline-block !important;
  font-size: 0.775rem !important;
`;

const DisplayPhotos = styled.div`
  position: relative;
  margin: 0 auto;
  overflow-y: hidden;
  max-height: 585px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    object-fit: cover;
    width: 95%;
    height: auto;
  }
`;

const UserDetails = styled.div`
  margin-top: 1rem;
  display: flex;
`;

const DetailField = styled(Grid)`
  margin-bottom: 24px !important;
  justify-content: space-between !important;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const PublicButton = styledMUI(Button)(({ width }) => ({
    color: '#fff',
    textTransform: 'capitalize',
    fontWeight: '400',
    minWidth: 25,
    backgroundColor: THEME_COLORS.primary,
    borderRadius: 4,
    width: `${width}%`,
    boxShadow: 'unset',
    '&:hover': {
        backgroundColor: THEME_COLORS.primaryHover,
    },
}));

const EditButton = styledMUI(Button)(({ width }) => ({
    color: THEME_COLORS.primary,
    textTransform: 'capitalize',
    fontWeight: '400',
    minWidth: 25,
    border: `1px solid ${THEME_COLORS.primary}`,
    backgroundColor: '#fff',
    borderRadius: 4,
    width: `${width}%`,
    boxShadow: 'unset',
    '&:hover': {
        backgroundColor: `${THEME_COLORS.subdued}10`,
    },
}));

const AssetHeader = styled.div`
  margin-top: 0.7rem;
  margin-bottom: 1rem;
`;

const Location = styled(Typography)`
  font-size: 0.7rem !important;
  margin-top: 4px !important;
  margin-bottom: 8px !important;
`;

const LocationIcon = styled(PlaceIcon)`
  fill: #717273 !important;
  font-size: 0.9rem !important;
  margin-top: -4px !important;
`;

const UserDescription = styled.div`
  margin-left: 12px;
  color: #717273;
`;

const Multiple = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const RatingSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.2rem;
`;

const Header = styled(SubHeader)`
    margin-bottom: 0.6rem !important;
    font-weight: 800 !important;
`;

const MinimalLink = styled(Link)`
  color: #4e5152 !important;
  text-decoration: unset !important;
`;

const RatingText = styled(Typography)`
  font-size: 1.25rem !important;
  margin: 0 12px !important;
`;

function getProfileActions(onShare, onSave) {
    return ([
        {
            label: 'Share',
            action: onShare
        },
        {
            label: 'Save',
            action: () => null,
            type: 'disabled',
            description: 'Feature coming soon!'
        },
    ]);
}

export function Mobile({
   openModal,
   categories,
   openAssetForm,
   onClose,
   updateAsset,
   errors,
   updating,
   resetErrors,
   onSwapp,
   userInfo,
   asset,
   userReviews,
   assetReviews,
   openShareAsset,
}) {
    const [openActionModal, setOpenActionModal] = useState(false);

    const handleShareModal = () => {
        openShareAsset();
        setOpenActionModal(false);
    }

    return (
        <FixBannerPageLayout>
            <ImageCarousel
                animation="slide"
                interval={8000}
                stopAutoPlayOnHover={true}
                indicators={asset.images.length > 1}
            >
                {
                    asset.images.map((img, i) => {
                        return (
                            <DisplayPhotos>
                                <img src={img || Placeholder} alt={`${asset.title} image ${i + 1}`} />
                            </DisplayPhotos>
                        );
                    })
                }
            </ImageCarousel>
            <Body10>
                {getCategoryLabelsFromValues(categories, [asset.category])[0]}
            </Body10>
            <AssetHeader container>
                <Header18>{asset.title}</Header18>
                {getCategoryLabelsFromValues(categories, asset.categories)
                    .map((category) => (
                        <SmallTag key={`tags-${category}`}>
                            #{category}
                        </SmallTag>
                    ))
                }
                <UserDetails>
                    <Avatar alt="user-profile-picture" src={asset.user.profilePicture} />
                    <UserDescription>
                        <MinimalLink href={`/profile/${asset.user.uuid}`}>
                            <Typography variant="body1">by {asset.user.firstName} {asset.user.lastName}</Typography>
                        </MinimalLink>
                        {
                            asset.user.displaylocation &&
                            <Location>
                            <LocationIcon fontSize="small"/>
                                {asset.user.displaylocation || 'No Location'}
                            </Location>
                        }
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Ratings name="read-only" readOnly size="medium" value={userReviews.userReviewAverage ?? 0} />
                            <MinimalLink href={`/profile/${asset.user.id}/reviews`}>
                                {
                                    userReviews.userReviewTotal > 0 &&
                                    <RatingCount variant="body2">
                                        ({userReviews.userReviewTotal})
                                    </RatingCount>
                                }
                            </MinimalLink>
                        </div>
                    </UserDescription>
                </UserDetails>
            </AssetHeader>
            <ActionButtonContainer>
                {
                    userInfo.id === asset.user.id &&
                    <PublicButton width={100} onClick={() => openAssetForm()}>Edit</PublicButton>
                }
                {
                    userInfo.id !== asset.user.id &&
                    <>
                        <PublicButton
                            width={95}
                            onClick={onSwapp}
                        >
                            Swap
                        </PublicButton>
                        <EditButton width={5} onClick={() => setOpenActionModal(true)}>
                            <MoreVertIcon sx={{fill: `${THEME_COLORS.primary} !important`}} />
                        </EditButton>
                    </>
                }
            </ActionButtonContainer>
            <ContentSection>
                <Header>Most likely to swap for</Header>
                {getCategoryLabelsFromValues(categories, asset.user.topCategories)
                    .map((category) => (
                        <Tag key={`most-likely-swap-${category}`}>
                            {category}
                        </Tag>
                    ))
                }
            </ContentSection>
            <ContentSection>
                <Header>Description</Header>
                <Body12 sx={{ whiteSpace: 'pre-wrap' }} gutterBottom>
                    {asset.description}
                </Body12>
            </ContentSection>
            <ContentSection>
                <Multiple>
                    <div>
                        <Header>
                            {asset.type === 'PRODUCT' ? 'Condition' : 'Expertise'}
                        </Header>
                        <DescriptiveRating
                            categoryType={asset.type}
                            value={asset.condition}
                            alignment="flex-start"
                            readOnly
                        />
                    </div>
                    <div>
                        <Header>
                            Delivery method
                        </Header>
                        <Body12>
                            {getDeliveryMethodFromValue(asset.deliveryMethod)}
                        </Body12>
                    </div>
                    {
                        asset.customizable &&
                        <div>
                            <Header>
                                Customization
                            </Header>
                            Open to customize
                        </div>
                    }
                </Multiple>
            </ContentSection>
            <ContentSection>
                <Header>Ratings</Header>
                <RatingSection>
                    {
                        assetReviews.reviews.length === 0 &&
                        'No ratings yet.'
                    }
                    {
                        assetReviews.reviews.length > 0 &&
                        <Ratings
                            name="read-only"
                            readOnly
                            size="large"
                            precision={0.5}
                            value={assetReviews.assetReviewAverage ?? 0}
                        />
                    }
                    {
                        assetReviews.reviews.length > 0 &&
                        <RatingText>
                            {assetReviews.assetReviewAverage} out of 5
                        </RatingText>
                    }
                </RatingSection>
                <ReviewList reviews={assetReviews} type="asset" isMobile={true} />
            </ContentSection>
            {
                openActionModal &&
                <ActionMenuModal
                    options={getProfileActions(handleShareModal)}
                    onClose={() => setOpenActionModal(false)}
                />
            }
            {
                openModal === 'ASSET_SHARE' &&
                <ShareContentModal
                    title={`Check this out on WeSuap - ${asset.title}`}
                    url={`${process.env.REACT_APP_PUBLIC_URL}/assets/${asset.uuid}`}
                    postMainImage={asset.postMainImage}
                    isMobile={true}
                    anchorEl={openModal === 'ASSET_SHARE' ? openModal : null}
                    onClose={onClose}
                />
            }
            <DialogForm
                label=""
                description=""
                isOpen={openModal === 'ASSET_FORM'}
                onClose={() => {
                    onClose();
                    resetErrors();
                }}
                closePosition="right"
                isMobile={true}
            >
                <AssetForm
                    onClose={() => {
                        onClose();
                        resetErrors();
                    }}
                    updateAsset={updateAsset}
                    errors={errors}
                    updating={updating}
                    uuid={asset.uuid}
                    isMobile={true}
                />
            </DialogForm>
        </FixBannerPageLayout>
    );
}