import { useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { fromApi } from "../../common/models/asset";
import { AuthContext } from "../../app/AuthContext";
import { filtersToQueryParams } from "../utils";
import { isArray } from "lodash/lang";

function convertFiltersToQuery(filters) {
    const searchParams = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
        if (value) {
            if (!isArray(value)) {
                searchParams.set(key, value);
            }
            if (isArray(value) && value.length > 0 && value[0]) {
                searchParams.set(key, value.join(','));
            }
        }
    });

    return searchParams.toString();
}

function useFetchFilteredAssets(query, history) {
    const { authContext } = useContext(AuthContext);
    const [filters, setFilters] = useState({
        category: query.get('category') ? (query.get('category')).split(',') : [],
        delivery_method: query.get('delivery_method') ? query.get('delivery_method').split(',') : [],
        level: query.get('level') ? query.get('level').split(',') : [],
        title: query.get('title') || '',
    });

    useEffect(() => {
        const { category, delivery_method, level, title } = filters;
        if([...category, ...delivery_method, ...level].length > 0 || title) {
            fetchAssets(filters);
        }
    }, []);

    const [loaded, setLoaded] = useState(false);
    const [assets, setAssets] = useState([]);

    const fetchAssets = (filterFields) => {
        const queryParam = filtersToQueryParams(filterFields);
        axios
            .get(`${process.env.REACT_APP_PUBLIC_API_URL}/assets${queryParam}`, {
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'x-access-token': authContext.token,
                },
            })
            .then(response => {
                setLoaded(true);
                setAssets(response.data.map(fromApi));
                return response.data;
            })
            .catch(error => {
                if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
            });
    };

    const handleFilterChange = useCallback((condition) => {
        const newFilters = {
            ...filters,
            ...condition,
        };

        setFilters(newFilters);
        const params = convertFiltersToQuery(newFilters);
        history.push(`${window.location.pathname}?${params}`);
        fetchAssets(newFilters);
        window.scrollTo(0, 0);
    }, [filters]);

    const handleSearchChange = useCallback((search) => {
        const newFilters = {
            ...filters,
            title: search,
        };
        const params = convertFiltersToQuery(newFilters);
        history.push(`${window.location.pathname}?${params}`);
        setFilters(newFilters);
        fetchAssets(newFilters);
    }, [filters]);

    const clearFilterAndSearch = () => {
        setFilters({
            category: [],
            delivery_method: [],
            level: [],
            title: '',
        });
        history.push(window.location.pathname);
        setAssets([]);
        setLoaded(false);
    }

    return { assets, loaded, handleFilterChange, handleSearchChange, filters, clearFilterAndSearch };
};

export default useFetchFilteredAssets;
