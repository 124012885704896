import React from 'react';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';

import styled from 'styled-components';
import SignupBg from '../../../assets/images/signin_bg.png';

import useFetchInvitationKey from "./useFetchInvitationKey";
import { SplitImageLayoutForm } from "../../common/SplitLayoutForm";
import { SignupForm } from "./SignupForm";
import FORMAT_SIZES from "../../common/constants/FormatSizes";
import HomeLogo from "../../common/HomeLogo";
import { Header18 } from "../../common/Typography";
import { THEME_COLORS } from "../../common/colors";
import { MobileForm } from "../../common/Mobile/MobileForm";

const Header = styled(Header18)`
  margin-bottom: 4px !important;
`;

const MobileBackground = styled.div`
  background-color: ${THEME_COLORS.primary};
  height: 100vh;
`;

const HeaderContainer = styled.div`
  text-align: center;
  h1 {
    margin-top: 1.5rem !important;
    margin-bottom: 1rem !important;
  }
`;

function SignupWeb() {
  return (
      <SplitImageLayoutForm
          imagePosition="right"
          $logoPosition="left"
          image={SignupBg}
      >
        <div>
          <Header component="h1" variant="h5">
            Create an account
          </Header>
          <Typography component="h2" variant="body1">
            Signing up for WeSuap is fast and 100% free
          </Typography>
          <SignupForm />
        </div>
      </SplitImageLayoutForm>
  );
}

function SignupMobile() {
  return (
      <MobileBackground>
        <MobileForm>
          <HeaderContainer>
            <HomeLogo
                noLink
                width={190}
                alignment="center"
            />
            <Header component="h1" variant="h5">
              Create an account
            </Header>
            <Typography component="h2" variant="body1">
              Signing up for WeSuap is fast and 100% free
            </Typography>
          </HeaderContainer>
          <SignupForm />
        </MobileForm>
      </MobileBackground>
  );
}

function Signup({ history, format }) {
  const query = new URLSearchParams(useLocation().search);
  const inviteToken = query.get('invite-token');
  useFetchInvitationKey(inviteToken, history);

  return (
      format === FORMAT_SIZES.MOBILE ?
          <SignupMobile /> :
          <SignupWeb />
  );
}

export default Signup;
