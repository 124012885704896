import { PageLayout } from "./components/PageLayout";
import { Grid, Tooltip } from "@mui/material";
import Searchbar from "../common/Searchbar";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import useFetchUserAssets from "./hooks/useFetchUserAssets";
import styled from "styled-components";
import { Body10, Header12, Header16 } from "../common/Typography";
import Link from "@mui/material/Link";
import { AddNewAsset } from "./components/AddNewAsset";
import DialogForm from "../common/DialogForm";
import { AssetForm } from "./components/AssetForm";
import useEditAsset from "./hooks/useEditAsset";
import useUserInfo from "../profile/hooks/useUserInfo";
import FORMAT_SIZES from "../common/constants/FormatSizes";
import { FixBannerPageLayout } from "../common/Mobile/FixBannerPageLayout";

const ASSET_CARD_WIDTH = 122;

const AssetCardContainer = styled.div`
  text-align: center;
  width: ${ASSET_CARD_WIDTH}px;

  @media screen and (min-width: 375px) {
    width: ${ASSET_CARD_WIDTH + 26}px;
  }

  @media screen and (min-width: 425px) {
    width: ${ASSET_CARD_WIDTH + 48}px;
  }
  
  img {
    cursor: pointer;
  }
`;

const AssetCardList = styled.div`
  margin-top: 4rem;
  display: flex;
  flex-wrap: wrap;
  column-gap: 8%;
  row-gap: ${({ isMobile }) => isMobile ? '2rem' : '6%'};
`;

const AssetTitleContainer = styled.div`
  text-align: center;
  margin-top: 0.5rem;
  height: 40px;
`;

const ViewContainer = styled.div`
  min-height: 100vh;
`;

const AssetLink = styled(Link)`
  text-decoration: unset !important;
  font-weight: bold !important;
  color: #4E5152 !important;
`;

const Subheader = styled(Grid)`
  align-items: center;
  justify-content: space-between;
`;

const HeaderContainer = styled.div`
  text-align: ${({ isMobile }) => isMobile ? 'center' : 'auto'};
`;


const SearchContainer = styled.div`
  ${({ isMobile }) => !isMobile && 'min-width: 300px;'}
  ${({ isMobile }) => isMobile && 'margin-top: 2rem;'}
`;

const AssetViewCard = styled.div`
  width: ${ASSET_CARD_WIDTH}px;
  height: ${ASSET_CARD_WIDTH  + 10}px;

  @media screen and (min-width: 375px) {
    width: ${ASSET_CARD_WIDTH + 26}px;
    height: ${ASSET_CARD_WIDTH  + 36}px;
  }

  @media screen and (min-width: 425px) {
    width: ${ASSET_CARD_WIDTH + 48}px;
    height: ${ASSET_CARD_WIDTH  + 58}px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    flex-shrink: 0;
    width: 100%;
    min-height: 100%;
  }
`;

function TruncateTitle({ children }) {
    if (children.length > 36) {
        return (
            <Tooltip title={children}>
                <Header12>{children.slice(0, 36)}...</Header12>
            </Tooltip>
        );
    }

    return <Header12>{children}</Header12>
}

export function ViewUserAssetPage({ format, match, userInfo }) {
    const isMobile = format === FORMAT_SIZES.MOBILE;
    const userId = match.params.id;
    const history = useHistory();
    const { userAssets, fetchAssets } = useFetchUserAssets(userId ? { uuid: userId } : userInfo);
    const { userInfo: user } = useUserInfo(userId);
    const [openModal, setOpenModal] = useState(null);
    const [assetId, setAssetId] = useState(null);
    const currentUserAssets = window.location.pathname === '/my-assets' || (userInfo && userId === userInfo.id?.toString());
    const Component = isMobile ? FixBannerPageLayout : PageLayout;
    const openAssetForm = (id) => {
        setAssetId(id);
        setOpenModal('ASSET_FORM');
    }

    const onClose = ()=> {
        setOpenModal(null);
        setAssetId(null);
    };

    const { updateAsset, createAsset, loading: updating, errors } = useEditAsset(onClose, fetchAssets);

    if (window.location.pathname !== '/my-assets' && currentUserAssets) {
        history.push('/my-assets');
    }

    return (
        <Component>
            <ViewContainer>
                <HeaderContainer isMobile={isMobile}>
                    <Header16>
                        { currentUserAssets ? 'My Assets Wallet' : `${user.firstname}'s Assets Wallet` }
                    </Header16>

                    <Subheader container>
                        <Grid item xs={12} sm={12} lg={6}>
                            <Body10>I would like to enter a swap by offering...</Body10>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6}>
                            <SearchContainer isMobile={isMobile}>
                                <Searchbar onSearch={fetchAssets} />
                            </SearchContainer>
                        </Grid>
                    </Subheader>
                </HeaderContainer>
                <AssetCardList isMobile={isMobile}>
                    {
                        currentUserAssets &&
                        <AssetCardContainer style={{marginLeft: 0}}>
                            <AddNewAsset
                                size={isMobile ? 'mobile' : 'md'}
                                openAssetForm={() => openAssetForm(null)}
                            />
                            <AssetTitleContainer>
                                <TruncateTitle>Add new asset</TruncateTitle>
                            </AssetTitleContainer>
                        </AssetCardContainer>
                    }
                    {
                        userAssets.map((asset) => {
                            return (
                                <AssetCardContainer key={`asset-list-${asset.category}`}>
                                    <AssetViewCard>
                                        <img
                                            onClick={() => history.push(`/assets/${asset.uuid}`)}
                                            alt={asset.title}
                                            src={asset.mainImage}
                                        />
                                    </AssetViewCard>
                                    <AssetTitleContainer>
                                        <TruncateTitle>{asset.title}</TruncateTitle>
                                    </AssetTitleContainer>
                                </AssetCardContainer>
                            );
                        })
                    }
                </AssetCardList>
                {openModal === 'ASSET_FORM' &&
                    <DialogForm
                        label=""
                        description=""
                        onClose={onClose}
                        closePosition="right"
                        isMobile={isMobile}
                    >
                        <AssetForm
                            onClose={onClose}
                            updateAsset={updateAsset}
                            createAsset={createAsset}
                            errors={errors}
                            updating={updating}
                            uuid={assetId}
                            isMobile={isMobile}
                        />
                    </DialogForm>
                }
            </ViewContainer>
        </Component>
    );
}