import React, { useState } from "react";
import { Add as AddIcon, Remove as RemoveIcon } from "@material-ui/icons";
import { PanelSection, ProfilePicture, Tag, TruncateButton } from "../styledComponents";
import Placeholder from "../../../assets/images/placeholderImage.png";
import LogoutButton from "../components/LogoutButton";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { NoDataYet, SubHeader, TagDisplay } from "./styles";
import { RatingLink, Ratings, RatingText } from "../../common/RatingDisplays";
import useFetchReviews from "../../review/useFetchReviews";
import { Typography } from "@mui/material";
import {TopCategories} from "./components/TopCategories";

const DEFAULT_MAX_CATEGORIES_DISPLAY = 8;

const SavingText = styled(Typography)`
  font-size: 1.75rem !important;
`;

const LeftPanel = styled.div`
  grid-area: LeftPanel;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #F4F5F4;
  padding: 0 2rem;
`;

const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ProfileLabel = styled(SubHeader)`
  margin-bottom: 0.6rem !important;
  font-weight: 800 !important;
`;

const currentFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export function LeftSection({ swappMetrics, type, user, userLookingForList }) {
    const history = useHistory();

    const { reviews } = useFetchReviews('user', user.uuid);

    return (
        <LeftPanel>
            <div>
                <ProfilePicture
                    variant="rounded"
                    src={user.profilepicture || Placeholder}
                />
                <PanelSection>
                    <ProfileLabel>Ratings:</ProfileLabel>
                    {
                        type === 'view' && reviews.reviews.length === 0 &&
                        <NoDataYet>No ratings yet</NoDataYet>
                    }
                    {
                        type === 'public' && reviews.reviews.length === 0 &&
                        <NoDataYet>Be the first to rate</NoDataYet>
                    }
                    {
                        reviews.reviews.length > 0 &&
                        <RatingContainer>
                            <RatingContainer>
                                <Ratings
                                    name="read-only"
                                    readOnly
                                    size="medium"
                                    precision={0.5}
                                    value={reviews.userReviewAverage ?? 0}
                                />
                                <RatingText>
                                    {reviews.userReviewAverage}
                                </RatingText>
                            </RatingContainer>
                            <RatingLink href={`/profile/${user.uuid}/reviews`}>
                                View all
                            </RatingLink>
                        </RatingContainer>
                    }
                </PanelSection>
                <PanelSection>
                    <ProfileLabel>Your savings so far:</ProfileLabel>
                    {
                        !!swappMetrics.completedSwappsCount &&
                            <SavingText>
                                {currentFormatter.format(swappMetrics.totalSavings)}
                            </SavingText>
                    }
                    {
                        type === 'view' && !swappMetrics.completedSwappsCount &&
                        <NoDataYet>No swap savings yet</NoDataYet>
                    }
                    {
                        type === 'public' && !swappMetrics.completedSwappsCount &&
                        <NoDataYet>Be the first to swap with {user.firstname}</NoDataYet>
                    }
                </PanelSection>
                {
                    type === 'view' &&
                    <TopCategories
                        user={user}
                        userLookingForList={userLookingForList}
                    />
                }
            </div>
            {type === 'view' && <LogoutButton history={history}/>}
        </LeftPanel>
    );
}