import axios from 'axios';
import _ from 'lodash';
import { useContext, useState } from 'react';
import { AuthContext } from "../../app/AuthContext";
import {genericSuccess, genericUpdateError} from "../../toaster/utils";
import {predefineCompleteSwapp} from "../predefinedMessages";

function useUpdateChatStatus(onUpdateSwapContract, currentUser, setOpenContractModal, fetchChatDetailsBySerialId) {
    const { authContext } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);

    const updateContractStatus = (chatId, serialId, status) => {
        setIsLoading(true);
        axios
            .put(`${process.env.REACT_APP_PUBLIC_API_URL}/chat/${chatId}`,
                { serialId, status },
                {
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'x-access-token': authContext.token,
                    },
                })
            .then(response => {
                setIsLoading(false);
                onUpdateSwapContract(predefineCompleteSwapp(currentUser.firstName));
                setOpenContractModal('REVIEW_FORM');
                genericSuccess('You successfully completed the swap.')
                return response.data;
            })
            .catch(error => {
                if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
                genericUpdateError(`Failed to complete the swap: ${error}`);
            });
    };

    return { updateContractStatus, isLoading };
};

export default useUpdateChatStatus;
